import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar
} from "recharts";
import { getInsightData, getEvaluatorName } from "./EvaluatorApiRequest";
import { toast } from "react-toastify";
import Sidebar from "./PlatformSidebar";
import HistorySideBarForInsight from "./HistorySideBarForInsight";

const ExploreViewV2: React.FC = () => {
  const [insightData, setInsightData] = useState<any[]>([]);
  const [evaluatorData, setEvaluatorData] = useState<Record<string, string[]>>({});
  const [selectedRuns, setSelectedRuns] = useState<string[]>([]);
  const [selectedEvaluators, setSelectedEvaluators] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [insightError, setInsightError] = useState<string | null>(null);
  const [evaluatorError, setEvaluatorError] = useState<string | null>(null);
  const [isEvaluatorDropdownOpen, setIsEvaluatorDropdownOpen] = useState(false);
  const [isRunTableDropdownOpen, setIsRunTableDropdownOpen] = useState(false);
  const [isHistorySideBarOpen, setIsHistorySideBarOpen] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setInsightError(null);
      setEvaluatorError(null);

      try {
        const insightResult: {
          runId: string;
          metrics: { key: string; max: number; min: number; avg: number }[];
        }[] = await getInsightData();

        const metricKeys: string[] = Array.from(
          new Set(insightResult.flatMap((run) => run.metrics.map((m) => m.key)))
        );

        const evaluatorResult = await getEvaluatorName(metricKeys);
        setEvaluatorData(evaluatorResult);

        const updatedInsightData = insightResult.map((run) => ({
          ...run,
          metrics: run.metrics.map((m) => ({
            ...m,
            key: evaluatorResult[m.key]?.[0] || m.key,
          })),
        }));

        setInsightData(updatedInsightData);
      } catch (error) {
        setInsightError("Failed to load historical insights");
        toast.error("Failed to load historical insights");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const BAR_COLORS = [
    "#4e79a7", // Blue
    "#f28e2b", // Orange
    "#e15759", // Red
    "#76b7b2", // Teal
    "#59a14f", // Green
    "#edc949", // Yellow
    "#af7aa1", // Purple
    "#ff9da7", // Pink
    "#9c755f", // Brown
    "#bab0ab", // Grey
  ];
  const MAX_VISIBLE_LABELS = 6;
  if (loading) {
    return (
      <div className="flex h-full">
        <div className="w-64 flex-none">
          <Sidebar />
        </div>
        <div className="flex justify-center items-center flex-1">
          Composo is crunching the data for you...
        </div>
      </div>
    );
  }

  if (insightError || evaluatorError) {
    return (
      <div className="flex h-full">
        <div className="w-64 flex-none border-r">
          <Sidebar />
        </div>
        <div className="flex justify-center items-center flex-1 text-red-500">
          {insightError || evaluatorError}
        </div>
      </div>
    );
  }

  if (!insightData || insightData.length === 0) {
    return (
      <div className="flex h-full">
        <div className="w-64 flex-none border-r">
          <Sidebar />
        </div>
        <div className="flex justify-center items-center flex-1">
          No insight data available
        </div>
      </div>
    );
  }

  const metricKeys = Array.from(
    new Set(insightData.flatMap((run) => run.metrics.map((m: any) => m.key)))
  ).sort();
  const availableEvaluators = Array.from(
    new Set(
      insightData
        .filter((run) => selectedRuns.includes(run.runid))
        .flatMap((run) => run.metrics.map((m: any) => m.key))
    )
  ).sort();
  const activeEvaluators = selectedEvaluators.length > 0 ? selectedEvaluators : availableEvaluators;

  const filteredData = metricKeys
    .filter((key) => activeEvaluators.includes(key))
    .map((key) => {
      const row: any = { evaluator: key };
      insightData.forEach((run) => {
        if (selectedRuns.includes(run.runid)) {
          const metric = run.metrics.find((m: any) => m.key === key);
          row[run.runid] = metric ? metric.avg : null;
        }
      });
      return row;
    });

  const CustomTick = (props: any) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`} style={{ cursor: "pointer" }}>
        <title>{payload.value}</title>
        <text textAnchor="middle" fontSize={14} fill="#666" dy={15}>
          {payload.value}
        </text>
      </g>
    );
  };

  const runMapping = insightData.reduce((acc: Record<string, string>, run) => {
    acc[run.runid] = run.name;
    return acc;
  }, {});

  const handleRunSelect = (runId: string) => {
    setSelectedRuns((prevRuns) =>
      prevRuns.includes(runId) ? prevRuns.filter((id) => id !== runId) : [...prevRuns, runId]
    );
    const availableEvaluators = Array.from(
      new Set(
        (insightData || [])
          .filter((run) => {
            console.log("Checking run:", run);  // 打印整个 run 对象
            console.log("run.runid:", run?.runid, "runId:", runId); // 打印 runid 和 传入的 runId
            return run?.runid === runId;
          })
          .flatMap((run) => run.metrics.map((m: any) => m.key))
      )
    );

    setSelectedEvaluators((prevEvaluators) => {
      if (selectedRuns.includes(runId)) {
        return prevEvaluators.filter((evaluator) => !availableEvaluators.includes(evaluator));
      } else {
        return Array.from(new Set([...prevEvaluators, ...availableEvaluators]));
      }
    });
    setIsEvaluatorDropdownOpen(false);
  };


  const handleHistorySideBarToggle = () => {
    setIsHistorySideBarOpen(!isHistorySideBarOpen);
    setIsEvaluatorDropdownOpen(false);
  }

  return (
    <div className="flex h-full">
      <div className="w-64 flex-none border-r">
        <Sidebar />
      </div>
      <main className="flex-1 flex flex-col p-6 overflow-auto">
        <div className="flex justify-end gap-x-4 mb-4 z-[10]">
          {/* Select Evaluators */}
          <div className="dropdown relative">
            <button
              onClick={() => {
                setIsEvaluatorDropdownOpen(!isEvaluatorDropdownOpen);
                setIsRunTableDropdownOpen(false);
              }}
              className="btn bg-primary text-white px-3 py-1 rounded shadow-md text-sm"
              disabled={selectedRuns.length === 0}
            >
              Select Evaluators
            </button>
            {isEvaluatorDropdownOpen && (
              <ul
                className="menu p-2 shadow bg-base-100 rounded-box w-80 absolute right-0 mt-2 overflow-hidden max-h-[40vh] overflow-y-auto"
                onMouseDown={(e) => e.stopPropagation()}
              >
                <li>
                  <label className="block text-sm font-semibold mb-1">
                    Select Evaluators
                  </label>
                  {availableEvaluators.map((key) => (
                    <label
                      key={key}
                      className="flex items-center gap-2 cursor-pointer"
                      style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
                    >
                      <input
                        type="checkbox"
                        className="checkbox checkbox-primary"
                        checked={selectedEvaluators.includes(key)}
                        onChange={() => {
                          setSelectedEvaluators((prev) =>
                            prev.includes(key)
                              ? prev.filter((e) => e !== key)
                              : [...prev, key]
                          );
                        }}
                      />
                      <span className="flex-1 whitespace-normal break-words">
            {key}
          </span>
                    </label>
                  ))}
                </li>
              </ul>
            )}

          </div>

          {/* Select Runs */}
          <div className="dropdown relative">
            <button
              onClick={() => {
                setIsEvaluatorDropdownOpen(false);
                setIsHistorySideBarOpen(!isHistorySideBarOpen);
              }}
              className="btn bg-primary text-white px-3 py-1 rounded shadow-md text-sm"
            >
              Select Runs
            </button>

          </div>
        </div>


        <div className="flex-0 h-[40%] min-h-[300px] bg-gray-200 rounded-lg shadow p-6 mb-6 z-8">
          {selectedRuns.length === 0 || filteredData.length === 0 ? (
            <div className="flex justify-center items-center h-full">
              Please select at least one Run and one Evaluator
            </div>
          ) : (
            <ResponsiveContainer width="100%">
              <BarChart data={filteredData} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
                <XAxis
                  dataKey="evaluator"
                  tick={filteredData.length > MAX_VISIBLE_LABELS ? false : <CustomTick />}
                  interval={0}
                  tickLine={filteredData.length > MAX_VISIBLE_LABELS ? false : true}
                />

                <YAxis domain={[0, 1]} />
                <Tooltip />
                {selectedRuns.map((run, index) => (
                  <Bar
                    key={index}
                    dataKey={run}
                    name={runMapping[run] || run}
                    fill={BAR_COLORS[index % BAR_COLORS.length]}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        <div className="flex-1 flex gap-6">
          {/* Sheet */}
          <div className="flex-1 bg-white rounded-lg p-6 border overflow-hidden">

            <div className="overflow-y-auto border rounded-lg auto">
              <table className="w-full border-collapse border border-gray-300">
                <thead>
                <tr className="bg-gray-200 sticky top-0">
                  <th className="border border-gray-300 px-4 py-2">Evaluator</th>
                  <th className="border border-gray-300 px-4 py-2">Min</th>
                  <th className="border border-gray-300 px-4 py-2">Mean</th>
                  <th className="border border-gray-300 px-4 py-2">Max</th>
                </tr>
                </thead>
                <tbody>
                {filteredData.map((row, index) => {
                  const numericValues = Object.values(row).filter(
                    (v): v is number => typeof v === "number"
                  );
                  return (
                    <tr key={index} className="text-center">
                      <td className="border border-gray-300 px-4 py-2 whitespace-normal break-words">
                        {row.evaluator}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {numericValues.length > 0 ? Math.min(...numericValues).toFixed(2) : "N/A"}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {numericValues.length > 0
                          ? (numericValues.reduce((a, b) => a + b, 0) / numericValues.length).toFixed(2)
                          : "N/A"}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {numericValues.length > 0 ? Math.max(...numericValues).toFixed(2) : "N/A"}
                      </td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex-1 bg-white rounded-lg p-6 border overflow-x-auto">
            {selectedRuns.length === 0 || filteredData.length === 0 ? (
              <div className="flex justify-center items-center h-full">
                Please select at least one Run and one Evaluator
              </div>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <RadarChart cx="50%" cy="50%" outerRadius="80%" data={filteredData}>
                  <PolarGrid />
                  <PolarAngleAxis dataKey="evaluator" tick={{ fontSize: 12 }} />
                  <PolarRadiusAxis domain={[0, 1]} tick={{ fontSize: 12 }} />
                  <Tooltip />
                  {selectedRuns.map((run, index) => (
                    <Radar
                      key={index}
                      name={runMapping[run] || run}
                      dataKey={run}
                      stroke={BAR_COLORS[index % BAR_COLORS.length]}
                      fill={BAR_COLORS[index % BAR_COLORS.length]}
                      fillOpacity={0.15}
                    />
                  ))}
                </RadarChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
      </main>
      <div className="z-10">
        <HistorySideBarForInsight onRunSelect={handleRunSelect} selectedRuns={selectedRuns} isSidebarOpen={isHistorySideBarOpen} handleToggle={handleHistorySideBarToggle} />
      </div>
    </div>
  );
};

export default ExploreViewV2;
