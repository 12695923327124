import React, { useState, useEffect } from "react";

interface ScreenSizeCheckProps {
  children: React.ReactNode;
}

export function ScreenSizeCheck({ children }: ScreenSizeCheckProps) {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768 && window.innerHeight >= 700);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 768 && window.innerHeight >= 700);
    };

    window.addEventListener("resize", handleResize);

    // Clean up
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!isLargeScreen) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center p-10">
          Composo's platform is only usable with laptops or tablets. Please enlarge your window or use a larger device.
        </div>
      </div>
    );
  }

  return <>{children}</>;
}
