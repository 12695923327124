import React, { Fragment, useState } from "react";
import { RectangleGroupIcon, DocumentIcon, UserCircleIcon, LightBulbIcon, ScaleIcon, EnvelopeIcon } from "@heroicons/react/20/solid";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import urlJoin from "url-join";
import { Dialog, Transition } from "@headlessui/react";
import { CALENDLY_LINK, DOCS_LINK } from "./globals";

// Import API function
import { getRuns } from "./ApiRequest";

// Define interface for location state
interface LocationState {
  pathname: string;
  search: string;
}

// Define interface for SidebarButton props
interface SidebarButtonProps {
  name: string;
  href?: string;
  onClick?: () => void;
  Icon: React.ComponentType<any>;
  selectedIf?: (location: LocationState) => boolean;
}

// Sidebar button component
const SidebarButton: React.FC<SidebarButtonProps> = ({ name, href, onClick, Icon, selectedIf }) => {
  const location = useLocation(); // Get the current location
  const pathname = location.pathname;
  const search = location.search;

  let isSelected = false;

  if (selectedIf) {
    isSelected = selectedIf({ pathname, search });
  } else if (href) {
    const linkPath = href.split('?')[0];
    isSelected = pathname === linkPath || pathname.startsWith(linkPath + '/');
  }
  let styleSupplement = "";

  // Add styles if the button is selected
  if (selectedIf && selectedIf(location as LocationState)) {
    styleSupplement = "bg-neutral";
  }

  // Define common styles for the button
  const item_style = [
    "group flex flex-row items-center justify-start p-4 px-8 text-sm rounded-none hover:bg-neutral text-primary w-full font-semibold",
    styleSupplement,
  ].join(" ");

  // Render the button as a link if href is provided
  if (href) {
    if (href.startsWith("http") || href.startsWith("mailto")) {
      return (
        <a href={href} className={`link-reset ${item_style}`}>
          <Icon className="mr-4 w-5 h-5" aria-hidden="true" />
          <span>{name}</span>
        </a>
      );
    }
    return (
      <Link to={href} className={`link-reset ${item_style}`}>
        <Icon className="mr-4 w-5 h-5" aria-hidden="true" />
        <span>{name}</span>
      </Link>
    );
  }

  // Render the button as a clickable element if onClick is provided
  if (onClick) {
    return (
      <button onClick={onClick} className={item_style}>
        <Icon className="mr-4 w-5 h-5" aria-hidden="true" />
        <span>{name}</span>
      </button>
    );
  }

  // Render nothing if no href or onClick is provided
  return null;
};

// Main Sidebar component
const Sidebar: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Define top menu items
  const currentHostname = window.location.hostname;
  const topMenuItems: SidebarButtonProps[] =
    currentHostname.includes("app.composo.ai") || currentHostname.includes("localhost")
      ? [
        {
          name: "Playground",
          href: "/api-playground",
          Icon: RectangleGroupIcon,
          selectedIf: (location) =>
            location.pathname.startsWith("/api-playground") ||
            location.pathname === "/",
        },
        {
          name: "Insights",
          href: "/insights",
          Icon: LightBulbIcon,
          selectedIf: (location) => location.pathname.startsWith("/insights"),
        },
        {
          name: "Evaluators",
          href: "/evaluators",
          Icon: ScaleIcon,
          selectedIf: (location) => location.pathname.startsWith("/evaluators"),
        },
      ]
      : [];

  // Define bottom menu items
  const bottomMenuItems: SidebarButtonProps[] = [
    {
      name: "Docs",
      href: DOCS_LINK,
      Icon: DocumentIcon,
    },
    {
      name: "Profile",
      href: "/control-center",
      Icon: UserCircleIcon,
    },
    {
      name: "Contact",
      href: "mailto:contact@composo.ai",
      Icon: EnvelopeIcon,
    }
  ];

  return (
    <div className="flex flex-col h-full bg-mainlightgray text-titlebrown border-r shrink-0 w-48 justify-start items-center shadow-inner">
      {/* Logo */}
      <img
        src={urlJoin(import.meta.env.BASE_URL, "/logo.svg")}
        className="mr-4 h-6 m-2 my-8 mt-6 hover:cursor-pointer"
        alt="Composo logo"
        onClick={() => {
          window.location.href = "/";
        }}
      />

      {/* Menu sections */}
      <div className="flex flex-col flex-grow justify-between items-start w-full pb-4">
        {/* Render top menu items */}
        <div className="w-full">
          {topMenuItems.map((item) => (
            <SidebarButton key={item.name} {...item} />
          ))}
        </div>

        {/* Render bottom menu items */}
        <div className="w-full">
          {bottomMenuItems.map((item) => (
            <SidebarButton key={item.name} {...item} />
          ))}
        </div>

        {/* Modal for additional actions */}
        <Transition show={isModalOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 z-10" onClose={closeModal}>
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-titlebrown p-6">
                    Talk to us!
                  </Dialog.Title>
                  <button onClick={closeModal} className="absolute top-6 right-6">
                    <XCircleIcon className="w-5 h-5" aria-hidden="true" />
                  </button>
                  <Dialog.Description as="p" className="text-sm text-maindarkgray px-6">
                    We're here to help! You can either send us an email or book a call.
                  </Dialog.Description>
                  <div className="p-6 flex flex-col items-center">
                    <a
                      href="mailto:seb@composo.ai"
                      className="link-reset btn btn-primary mb-4 px-14"
                      onClick={closeModal}
                    >
                      Email Us
                    </a>
                    <a
                      href={CALENDLY_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-reset btn btn-outline px-10"
                      onClick={closeModal}
                    >
                      Book a call
                    </a>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
};

export default Sidebar;
