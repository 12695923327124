import axios, { AxiosRequestConfig } from "axios";
import urlJoin from "url-join";
import { fetchAuthSession } from "aws-amplify/auth";

const getAccessTokenPromise = async (): Promise<string | null> => {
  try {
    const { tokens } = await fetchAuthSession();
    return tokens?.accessToken?.toString() || null;
  } catch (error) {
    console.error("Error fetching access token:", error);
    return null;
  }
};

// Reusable API request function
const makeApiRequestPromise = async ({
                                       path,
                                       method,
                                       body,
                                       baseUrl,
                                     }: {
  path: string;
  method: string;
  body?: any;
  baseUrl?: string;
}): Promise<any> => {
  const base = baseUrl || import.meta.env.VITE_PLATFORM_BACKEND_API_URL;
  const apiUrl = urlJoin(base, "api" + path);

  const headers: AxiosRequestConfig["headers"] = {
    "Content-Type": "application/json",
  };

  const token = await getAccessTokenPromise();
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  try {
    const response = await axios({
      method,
      url: apiUrl,
      data: JSON.stringify(body),
      headers,
    });

    return response.data;
  } catch (error) {
    console.error(`Error in ${method} request to ${path}:`, error);
    throw error;
  }
};

// CRUD API requests for evaluators

/**
 * Create a new evaluator.
 * @param evaluatorData - Object containing evaluator details.
 * @returns Newly created evaluator object.
 */
export const createEvaluator = async (evaluatorData: {
  evaluator_name: string;
  evaluate_criteria: string;
  type: string;
}): Promise<any> => {
  return await makeApiRequestPromise({
    path: "/evaluator",
    method: "POST",
    body: evaluatorData,
  });
};

/**
 * Get all evaluators.
 * @returns List of evaluator objects.
 */
export const getEvaluators = async (): Promise<any> => {
  return await makeApiRequestPromise({
    path: "/evaluator",
    method: "GET",
  });
};

/**
 * Delete an evaluator by ID.
 * @param evaluatorId - ID of the evaluator to be deleted.
 * @returns Void.
 */
export const deleteEvaluator = async (evaluatorId: number): Promise<void> => {
  return await makeApiRequestPromise({
    path: `/evaluator/${evaluatorId}`,
    method: "DELETE",
  });
};

/**
 * Update an existing evaluator by ID.
 * @param evaluatorId - ID of the evaluator to update.
 * @param updatedData - Object containing updated fields for the evaluator.
 * @returns Updated evaluator object.
 */
export const updateEvaluator = async (
  updatedData: {
    evaluator_name?: string;
    evaluate_criteria?: string;
  }
): Promise<any> => {
  return await makeApiRequestPromise({
    path: `/evaluator`,
    method: "PUT",
    body: updatedData,
  });
};

interface EvaluatorUpdateRequest {
  evaluatorName?: string;
  evaluateCriteria?: string;
  type?: string;
  notes?: string;
}

interface GetEvaluatorsByFolderResponse {
  folder: {
    id: number;
    folder_name: string;
    created_by: string;
    created_time: string;
  };
  evaluators?: {
    id: number;
    evaluator_name: string;
    evaluate_criteria: string;
    type: string;
    created_time: string;
  }[];
}

export const getEvaluatorsByFolder = async (
  folderId: number
): Promise<GetEvaluatorsByFolderResponse> => {
  return await makeApiRequestPromise({
    path: `/evaluator-folder/${folderId}`,
    method: "GET",
  });
};

/**
 * Generate an evaluator based on a given folder and prompt.
 * @param folderName - The folderName of the folder where the evaluator will be created.
 * @param prompt - The prompt to generate the evaluator.
 * @returns The generated evaluator object.
 */
export const generateEvaluator = async (prompt: string): Promise<any> => {
  return await makeApiRequestPromise({
    path: "/evaluator/generate",
    method: "POST",
    body: { prompt },
  });
};

/**
 * Generate an evaluator based on a given folder and prompt.
 * @param folderName - The folderName of the folder where the evaluator will be created.
 * @param prompt - The prompt to generate the evaluator.
 * @returns The generated evaluator object.
 */
export const generateEvaluatorByCriteria = async (criteria: string[]): Promise<any> => {
  return await makeApiRequestPromise({
    path: "/evaluator/generate-name",
    method: "POST",
    body: criteria ,
  });
};

/**
 * Get historical run insight data
 * @returns The generated evaluator object.
 */
export const getInsightData = async (): Promise<any> => {
  return await makeApiRequestPromise({
    path: "/eval/insight",
    method: "GET"
  });
};

/**
 * Get evaluator names by evaluation criteria
 * @param evaluateCriteriaList - A list of evaluation criteria strings.
 * @returns A dictionary where each key is an evaluate_criteria, and the value is a list of evaluator names.
 */
export const getEvaluatorName = async (evaluateCriteriaList: string[]): Promise<any> => {
  return await makeApiRequestPromise({
    path: "/evaluator/by-criteria",
    method: "POST",
    body: evaluateCriteriaList,
  });
};


export interface EvaluatorResponse {
  id: number;
  evaluator_name: string;
  evaluate_criteria: string;
  type: string;
  created_by: string; // UUID is represented as a string
  notes?: string;
  created_at: string; // Date can be stored as ISO string
}

interface EvaluatorListResponse {
  evaluators: EvaluatorResponse[];
}

/**
 * Get evaluator names by evaluation criteria
 * @returns A dictionary where each key is an evaluate_criteria, and the value is a list of evaluator names.
 */
export const getEvaluatorByUser = async (): Promise<EvaluatorResponse[]> => {
  const response: EvaluatorListResponse = await makeApiRequestPromise({
    path: "/evaluator",
    method: "GET"
  });

    return  response.evaluators;
};
