import React, { useState } from "react";

interface Evaluator {
  id: number;
  evaluator_name: string;
  evaluate_criteria: string;
  type: string;
  created_at: string;
}

interface AddEvaluatorModalProps {
  onClose: () => void;
  onAddEvaluators: (displayName: string, criteria: string) => void;
  evaluators: Evaluator[];
}

const AddEvaluatorModal: React.FC<AddEvaluatorModalProps> = ({
                                                               onClose,
                                                               onAddEvaluators,
                                                               evaluators
                                                             }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set());

  const filteredEvaluators = evaluators.filter(
    (evaluator) =>
      evaluator.evaluator_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      evaluator.evaluate_criteria.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const toggleSelection = (id: number) => {
    setSelectedIds((prev) => {
      const newSet = new Set(prev);
      newSet.has(id) ? newSet.delete(id) : newSet.add(id);
      return newSet;
    });
  };

  const handleAdd = () => {
    const selectedItems = Array.from(selectedIds)
      .map((id) => evaluators.find((e) => e.id === id))
      .filter((e): e is Evaluator => !!e);
    selectedItems.forEach((evaluator) => {
      onAddEvaluators(evaluator.evaluator_name, evaluator.evaluate_criteria);
    });

    onClose();
  };

  return (
    <div className="modal modal-open">
      <div className="modal-box">
        <h3 className="font-bold text-lg">Select Evaluators</h3>

        {/* Search Box */}
        <input
          type="text"
          placeholder="Search evaluators..."
          className="input input-bordered w-full mt-2"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        {/* Evaluator List */}
        <div className="mt-4 max-h-64 overflow-auto">
          {filteredEvaluators.map((evaluator) => (
            <div
              key={evaluator.id}
              className="flex items-center space-x-2 p-2 border-b hover:bg-base-200 cursor-pointer"
              onClick={() => toggleSelection(evaluator.id)}
            >
              <input
                type="checkbox"
                checked={selectedIds.has(evaluator.id)}
                onChange={() => toggleSelection(evaluator.id)}
                className="checkbox checkbox-primary"
                onClick={(e) => e.stopPropagation()}
              />
              <div className="flex-1">
                <p className="font-medium">{evaluator.evaluator_name}</p>
                <p className="text-sm text-gray-500">
                  Criteria: {evaluator.evaluate_criteria}
                </p>
                <p className="text-xs text-gray-400 mt-1">
                  Type: {evaluator.type}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Action Buttons */}
        <div className="modal-action mt-4">
          <button
            className="btn btn-primary flex-1"
            onClick={handleAdd}
            disabled={selectedIds.size === 0}
          >
            Add Selected ({selectedIds.size})
          </button>
          <button className="btn flex-1" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEvaluatorModal;