import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import { signUp } from "aws-amplify/auth";
import { Authenticator, useTheme, View, Theme } from "@aws-amplify/ui-react";
import ProfileSettings from "./ProfileSettings";
import { ScreenSizeCheck } from "./ScreenSizeCheck";
import { ProtectedRoute } from "./ProtectedRoute";
import { ThemeProvider } from "@aws-amplify/ui-react";
import { AUTH_PATH } from "./globals";
import urlJoin from "url-join";
import CompanyEmailValidator from "company-email-validator";
import APIPlayground from "./APIPlayground";
import EvaluatorsPage from "./Evaluator";
import ExploreViewV2 from "./ExploreViewV2";
import { DataGridProvider } from "./DataGridContext";
const ROUTES = {
  AUTH_PATH: AUTH_PATH,
  PROFILE_SETTINGS: "/control-center",
  API_PLAYGROUND: "/api-playground",
  EVALUATORS: "/evaluators",
  INSIGHTS: "/insights",
};
console.log("import.meta.env.BASE_URL", import.meta.env.BASE_URL);// ""

// CONFIGURATION FOR AMPLIFY AUTH PAGE
const formFields = {
  signIn: {
    username: {
      label: "Email",
      placeholder: "Enter your email",
    },
  },
  signUp: {
    username: {
      label: "Work Email",
      placeholder: "Enter your work email",
    },
  },
  resetPassword: {
    username: {
      label: "Email",
      placeholder: "Enter your email",
    },
  },
};

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <img
          src={urlJoin(import.meta.env.BASE_URL, "/logo.svg")}
          className="w-full h-14 mb-8 mt-10"
          alt="Composo logo"
        />
      </View>
    );
  },
};

const amplifyTheme: Theme = {
  name: "Custom Theme",
  tokens: {
    colors: {
      brand: {
        primary: {
          10: { value: "#AFD0CD" },
          80: { value: "#45918A" },
          90: { value: "#2B817A" },
          100: { value: "#107169" },
        },
      },
    },
  },
};

const services = {
  async handleSignUp(formData: any) {
    let { username, password } = formData;
    username = username.toLowerCase();

    const isCompanyEmail = CompanyEmailValidator.isCompanyEmail(username);

    if (isCompanyEmail) {
      return signUp({
        username,
        password,
      });
    } else {
      throw new Error("Please sign up with your work email.");
    }
  },
};
const currentHostname = window.location.hostname.toLowerCase();
const defaultRoute = currentHostname.includes("app.composo.ai")
  ? ROUTES.API_PLAYGROUND
  : ROUTES.PROFILE_SETTINGS;
const AppRouter: React.FC = () => {
  return (
    <ScreenSizeCheck>
      <Authenticator.Provider>
        <Router>
          <Routes>
            <Route
              path={ROUTES.AUTH_PATH}
              element={
                <ThemeProvider theme={amplifyTheme}>
                  <Authenticator services={services} formFields={formFields} components={components}>
                    <Navigate to="/" replace />
                  </Authenticator>
                </ThemeProvider>
              }
            />
            <Route path={"/"} element={<Navigate to={defaultRoute} replace />} />
            <Route
              path={ROUTES.PROFILE_SETTINGS}
              element={
                <ProtectedRoute>
                  <ProfileSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.API_PLAYGROUND}
              element={
                <ProtectedRoute>
                  <DataGridProvider>
                    <APIPlayground />
                  </DataGridProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.EVALUATORS}
              element={
                <ProtectedRoute>
                  <DataGridProvider>
                    < EvaluatorsPage />
                  </DataGridProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.INSIGHTS}
              element={
                <ProtectedRoute>
                  <DataGridProvider>
                  <ExploreViewV2 />
                    </DataGridProvider>
                </ProtectedRoute>
              }
            />
            {/* 404 route */}
            <Route
              path="*"
              element={
                <div>
                  <div>404 - Not Found</div>
                </div>
              }
            />
          </Routes>
        </Router>
      </Authenticator.Provider>
    </ScreenSizeCheck>
  );
};

export default AppRouter;
