import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import {
  CellValueChangedEvent,
  ColDef,
  GridApi,
  GridReadyEvent, ICellEditorParams, ICellRendererParams,
  ITooltipParams,
  ValueFormatterParams
} from "ag-grid-community";
import Sidebar from "./PlatformSidebar";
import Papa from 'papaparse';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

// Import xlsx library for XLSX support
import * as XLSX from "xlsx";
import { listApiKey } from "./ProfileSettings";
import {
  makeApiRequestPromise,
  fetchUserIdByEmail,
  processAndSendInBatches,
  makeEvaluateRequest,
  createRun,
  getRun
} from "./ApiRequest";
import * as Console from "node:console";
import { fetchAuthSession } from "aws-amplify/auth";
import { v4 as uuidv4 } from 'uuid';
import HistorySidebar from "./HistorySidebar";
import { getEvaluatorByUser, EvaluatorResponse, generateEvaluatorByCriteria } from "./EvaluatorApiRequest";
import AddEvaluatorModal from "./AddEvaluatorModal";
import { LightBulbIcon } from "@heroicons/react/20/solid";
import { useDataGrid } from "./DataGridContext";

// Create a Context for the User
interface UserContextType {
  userId: string | null;
  userEmail: string | null;
  setUserId: (id: string | null) => void;
  setUserEmail: (email: string | null) => void;
}

const UserContext = createContext<UserContextType>({
  userId: null,
  userEmail: null,
  setUserId: () => {},
  setUserEmail: () => {},
});

// Constants
export const REQUIRED_COLUMNS = ["Input", "Response"];
export const DEFAULT_COLUMNS = ["Input", "Response", "Notes"];
export const DEFAULT_COLUMNS_LOWER = DEFAULT_COLUMNS.map((col) => col.toLowerCase());

// Interfaces
export interface RowData {
  id: number;
  [key: string]: any; // Allow dynamic columns including required ones
}

export interface DataGridProps {
  gridData: RowData[];
  columnDefs: ColDef[];
  onCellValueChanged: (params: CellValueChangedEvent) => void;
  gridRef: React.RefObject<AgGridReact<RowData>>;
  onGridReady?: (params: GridReadyEvent) => void;
  rowWrapEnabled: boolean;
  handleCellFocused: (params: any) => void;
}

export interface DataToolbarProps {
  onDeleteSelectedRows: () => void;
  onDeleteSelectedColumns: () => void;
  onAddRow: () => void;
  columns: ColDef[];
  onDeleteColumn: (field: string) => void;
  onClearColumn: (field: string) => void;
  onEvaluateColumn: (field: string) => void;
  onAddColumn: (columnName: string, criteria: string) => void;
  onExportJSONL: () => void;
  onImportJSONL: (file: File) => void;
  onExportXLSX: () => void;
  onExportCSV: () => void;
  onImportXLSX: (file: File) => void;
  onImportCSV: (file: File) => void;
  onEvaluateAll: () => void;
  onLoadSampleData: () => void;
  rowWrapEnabled: boolean;
  onToggleRowWrap: () => void;
  isEvaluating: boolean;
  onClearAll: () => void;
  evaluators: EvaluatorResponse[];
  isEvaluatorsLoaded: boolean;
  gridRef: React.RefObject<AgGridReact<RowData>>;
  hasEvaluatorColumns: boolean;
}

export interface ExploreToolbarProps {
  evaluators: string[];
  selectedEvaluators: string[];
  setSelectedEvaluators: (evaluators: string[]) => void;
}

// DataGrid Component
export const DataGrid: React.FC<DataGridProps> = ({
  gridData,
  columnDefs,
  onCellValueChanged,
  gridRef,
  onGridReady,
  rowWrapEnabled,
  handleCellFocused
}) => {
  // Function to get color based on value relative to mean and standard deviation
  const getColorForValue = (value: number): string => {
    const normalized = Math.max(0, Math.min(1, value));

    const colorStops = [
      { pos: 0.0, color: [255, 0, 0] },
      { pos: 0.5, color: [255, 165, 0] },
      { pos: 1.0, color: [0, 128, 0] }
    ];

    let start = colorStops[0], end = colorStops[colorStops.length - 1];
    for (let i = 0; i < colorStops.length - 1; i++) {
      if (normalized >= colorStops[i].pos && normalized <= colorStops[i+1].pos) {
        start = colorStops[i];
        end = colorStops[i+1];
        break;
      }
    }

    const ratio = (normalized - start.pos) / (end.pos - start.pos);

    const r = Math.round(start.color[0] + (end.color[0] - start.color[0]) * ratio);
    const g = Math.round(start.color[1] + (end.color[1] - start.color[1]) * ratio);
    const b = Math.round(start.color[2] + (end.color[2] - start.color[2]) * ratio);

    return `rgba(${r},${g},${b},0.5)`;
  };
  const columnStats = useMemo(() => {
    const stats: { [key: string]: { mean: number; stdDev: number } } = {};

    columnDefs.forEach((colDef) => {
      const field = colDef.field;
      if (!field) return;

      const values = gridData.map((row) => parseFloat(row[field])).filter((value) => !isNaN(value));

      if (values.length > 0) {
        const mean = values.reduce((sum, value) => sum + value, 0) / values.length;
        const variance = values.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / values.length;
        const stdDev = Math.sqrt(variance);
        stats[field] = { mean, stdDev };
      } else {
        stats[field] = { mean: NaN, stdDev: NaN };
      }
    });
    return stats;
  }, [gridData, columnDefs]);

  const cellRenderer = (params: ICellRendererParams) => {
    // Format the cell value using centralized formatting logic
    const formattedValue = formatCellValue(params.value);

    const isDefaultColumn = params.colDef?.field && DEFAULT_COLUMNS_LOWER.includes(params.colDef.field.toLowerCase());

    return React.createElement(
      "div",
      {
        style: {
          maxHeight: isDefaultColumn && rowWrapEnabled ? "150px" : "none",
          overflowY: isDefaultColumn && rowWrapEnabled ? "auto" : "hidden",
          ...(isDefaultColumn &&
            rowWrapEnabled && {
              fontSize: "14px",
              lineHeight: "1",
              padding: "5px 8px",
            }),
        },
        className: "ag-cell-inner",
      },
      formattedValue
    );
  };

  const dynamicCellStyle = useCallback(
    (params: any) => {

      const field = params.colDef?.field;
      let value = params.value;
      if (Array.isArray(value) && value.length > 0) {
        value = value[0];
      }
      console.log("value", value);
      console.log(params)
      const numericValue = parseFloat(value);
      const baseStyle = {
        whiteSpace: rowWrapEnabled ? "pre-wrap" : "nowrap",
        ...(rowWrapEnabled && {
          overflowY: rowWrapEnabled ? "auto" : "hidden",
        })
      };
      if (!field || DEFAULT_COLUMNS_LOWER.includes(field.toLowerCase())) {
        return {
          ...baseStyle,
          backgroundColor: "transparent",
          color: "black",
        };
      }
      if (value === "N/A" || value === "-") {
        return {
          ...baseStyle,
          backgroundColor: "transparent",
          color: "black",
          fontStyle: "normal"
        };
      }

      if (!isNaN(numericValue) && columnStats[field]) {
        const color = getColorForValue(numericValue) || "transparent";
        return {
          ...baseStyle,
          backgroundColor: color,
          color: "black",
        };
      }

      if (value && isNaN(numericValue)) {
        return {
          ...baseStyle,
          backgroundColor: "white",
          color: "red",
        };
      }

      return {
        ...baseStyle,
        backgroundColor: "transparent",
        color: "black",
      };
    },
    [rowWrapEnabled, columnStats],
  );

  const formatCellValue = (value: any): string => {
    // Handle array values
    if (Array.isArray(value)) {
      if (value.length === 0) return '';

      // Prioritize numeric values
      const numericValue = value.find(item => typeof item === 'number');
      return numericValue !== undefined ? String(numericValue) : String(value[0]);
    }

    // Handle null/undefined values
    if (value == null) return '';

    // Handle unexpected object types
    if (typeof value === 'object') {
      try {
        return JSON.stringify(value);
      } catch (e) {
        console.error('Object stringification failed:', e);
        return '[Invalid Object]';
      }
    }

    // Handle primitive types
    return String(value);
  };

  const CustomArrayTextEditor = (props: ICellEditorParams) => {
    const { value, node, column } = props;

    const initialValue = Array.isArray(value) ? value[0] : value;

    const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      const updatedValue = Array.isArray(value) ? [newValue, ...value.slice(1)] : newValue;
      props.api.stopEditing();
      node.setDataValue(column.getId(), updatedValue);
    };

    return (
      <textarea
        autoFocus
        defaultValue={initialValue}
        onBlur={handleBlur}
        style={{ width: "100%", height: "200px", resize: "vertical" }}
      />
    );
  };

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      minWidth: 150,
      resizable: true,
      editable: true,
      cellEditor: CustomArrayTextEditor,
      cellEditorPopup: true,
      wrapText: rowWrapEnabled,
      autoHeight: rowWrapEnabled,
      cellStyle: dynamicCellStyle,
      cellRenderer: cellRenderer,
      tooltipValueGetter: (params) => {
        const { value } = params;
        if (Array.isArray(value) && value.length > 1) {
          return value[1]; // The explanation string
        }
        // fallback: either no array or no explanation
        return null;
      },
    }),
    [rowWrapEnabled, dynamicCellStyle],
  );
  const { criteriaToNameMap } = useDataGrid();

  const processedColumnDefs = useMemo(() => {

    return columnDefs
      .map(col => {
        const field = col.field?.toLowerCase() || '';
        if (['context', 'reference'].includes(field)) {
          return { ...col, hide: true }
        }
        return col
      })
      .map(col => {
      if (!DEFAULT_COLUMNS_LOWER.includes(col.field!.toLowerCase())) {
        return {
          ...col,
          headerName: criteriaToNameMap.get(col.field!) || col.field,
          headerTooltip: col.field
        };
      }
      return col;
    });
  }, [columnDefs, criteriaToNameMap]);
  return (
    <div className="ag-theme-alpine w-full h-full flex-1 overflow-hidden">
      <AgGridReact
        columnDefs={processedColumnDefs}
        ref={gridRef}
        rowData={gridData}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowSelection="multiple"
        onCellValueChanged={onCellValueChanged}
        stopEditingWhenCellsLoseFocus={true}
        onGridReady={onGridReady}
        tooltipShowDelay={1}
        tooltipHideDelay={60000}
        getRowId={(params) => String(params.data.id)}
        onCellFocused={handleCellFocused}
        onHeaderFocused={handleCellFocused}
      />
    </div>
  );
};

// DataToolbar Component
export const DataToolbar: React.FC<DataToolbarProps> = ({
  onDeleteSelectedRows,
  onDeleteSelectedColumns,
  onAddRow,
  columns,
  onDeleteColumn,
  onClearColumn,
  onEvaluateColumn,
  onAddColumn,
  onExportJSONL,
  onImportJSONL,
  onExportXLSX,
  onImportXLSX,
  onImportCSV,
  onExportCSV,
  onEvaluateAll,
  onLoadSampleData,
  rowWrapEnabled,
  onToggleRowWrap,
  isEvaluating,
  onClearAll,
  evaluators,
  isEvaluatorsLoaded,
  gridRef,
  hasEvaluatorColumns
}) => {
  const [isAddColumnModalOpen, setIsAddColumnModalOpen] = useState(false);
  const [newColumnName, setNewColumnName] = useState("");
  const [selectedEvaluatorType, setSelectedEvaluatorType] = useState<string>("");
  const [selectedContextVerificationOption, setSelectedContextVerificationOption] = useState<string>("");
  const [selectedRewardType, setSelectedRewardType] = useState<string>("");
  const [selectedBinaryType, setSelectedBinaryType] = useState<string>("");

  // State for Export Modal
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [selectedExportType, setSelectedExportType] = useState<'jsonl' | 'xlsx' | 'csv'>('jsonl');

  const [isAddEvaluatorModalOpen, setIsAddEvaluatorModalOpen] = useState(false);

  return (
    <div className="space-y-6 h-full flex flex-col overflow-y-scroll">
      <h3 className="text-sm font-semibold">Data Settings</h3>
      <div className="grid grid-cols-2 gap-2">
        <button className="btn btn-sm btn-primary" onClick={onAddRow} disabled={isEvaluating}>
          Add Row
        </button>
        <button className="btn btn-sm btn-error" onClick={onDeleteSelectedRows} disabled={isEvaluating}>
          Delete Selected
        </button>
        <button className="btn btn-sm btn-warning col-span-2" onClick={onClearAll} disabled={isEvaluating}>
          Clear All
        </button>
      </div>
      {/* Row Wrap Toggle */}
      <div className="flex items-center space-x-2 mt-4">
        <input
          type="checkbox"
          checked={rowWrapEnabled}
          onChange={onToggleRowWrap}
          className="toggle toggle-primary"
          disabled={isEvaluating}
        />
        <label className="text-sm font-medium select-none">Enable Row Wrap</label>
      </div>

      <h3 className="text-sm font-semibold">Import / Export</h3>
      <div className="grid grid-cols-1 gap-2">
        {/* Import Button */}
        <div>
          <label
            htmlFor="import-file"
            className={`btn btn-sm btn-primary cursor-pointer w-full ${isEvaluating || !isEvaluatorsLoaded ? "opacity-50 pointer-events-none" : ""}`}
          >
            Import
          </label>
          <input
            id="import-file"
            type="file"
            accept=".jsonl,.xlsx,.csv"
            className="hidden"
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0];
                const fileType = file.name.split(".").pop();
                if (fileType === "jsonl") {
                  onImportJSONL(file);
                } else if (fileType === "xlsx") {
                  onImportXLSX(file);
                } else if (fileType === "csv") {
                  onImportCSV(file);
                }else {
                  alert("Unsupported file type");
                }
                e.target.value = ""; // reset input
              }
            }}
            disabled={isEvaluating || !isEvaluatorsLoaded}
          />
        </div>


        {/* Export Button */}
        <button
          className="btn btn-sm btn-primary w-full"
          onClick={() => setExportModalOpen(true)}
          disabled={isEvaluating}
        >
          Export
        </button>

        {/* Load Sample Data */}
        <button className="btn btn-sm btn-primary w-full" onClick={onLoadSampleData} disabled={isEvaluating}>
          Load Sample Data
        </button>
      </div>
      <h3 className="text-sm font-semibold">Choose Evaluators</h3>
      <div className="flex flex-col space-y-2 flex-1">
        <button
          className="btn btn-sm btn-primary"
          onClick={() => setIsAddEvaluatorModalOpen(true)}
          disabled={isEvaluating}
        >
          Add Evaluator
        </button>
        {isAddEvaluatorModalOpen && (
          <AddEvaluatorModal
            onClose={() => setIsAddEvaluatorModalOpen(false)}
            evaluators={evaluators}
            onAddEvaluators={onAddColumn}
          />
        )}
        <div className="flex flex-col space-y-2 flex-1">
          <button
            className="btn btn-sm btn-primary"
            onClick={onDeleteSelectedColumns}
            disabled={isEvaluating}
          >
            Delete Selected Column
          </button>
        </div>
      </div>

      {/*  /!* Existing Evaluators *!/*/}
      {/*  <div className="space-y-1 flex-1 h-32">*/}
      {/*    {columns*/}
      {/*      .filter((col) => !DEFAULT_COLUMNS_LOWER.includes(col.field!.toLowerCase()))*/}
      {/*      .map((col) => (*/}
      {/*        <div key={col.field} className="flex items-center justify-between bg-base-100 rounded p-2">*/}
      {/*          <span className="text-sm font-medium">{col.field}</span>*/}
      {/*          <div className="dropdown dropdown-left">*/}
      {/*            <label tabIndex={0} className="btn btn-xs btn-ghost">*/}
      {/*              &#x22EE;*/}
      {/*            </label>*/}
      {/*            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-32">*/}
      {/*              <li>*/}
      {/*                <a*/}
      {/*                  onClick={() => onEvaluateColumn(col.field!)}*/}
      {/*                  className={`${isEvaluating ? "opacity-50 cursor-not-allowed" : ""}`}*/}
      {/*                >*/}
      {/*                  Evaluate*/}
      {/*                </a>*/}
      {/*              </li>*/}
      {/*              <li>*/}
      {/*                <a onClick={() => onClearColumn(col.field!)}>Clear</a>*/}
      {/*              </li>*/}
      {/*              <li>*/}
      {/*                <a onClick={() => onDeleteColumn(col.field!)}>Remove</a>*/}
      {/*              </li>*/}
      {/*            </ul>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      ))}*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/* Add Evaluator Modal */}
      {isAddColumnModalOpen && (
        <div className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg">Add Evaluator</h3>
            <div className="mt-4">
              {/* Evaluator Type Selection */}
              <label className="label">
                <span className="label-text">Select Evaluator Category</span>
              </label>
              <select
                value={selectedEvaluatorType}
                onChange={(e) => {
                  setSelectedEvaluatorType(e.target.value);
                  setNewColumnName("");
                  setSelectedContextVerificationOption("");
                  setSelectedRewardType("");
                  setSelectedBinaryType("");
                }}
                className="select select-bordered w-full"
                disabled={isEvaluating}
              >
                <option value="" disabled>
                  Choose evaluator category
                </option>
                <option value="Continuous Reward">Continuous Reward</option>
                <option value="Binary">Binary</option>
                <option value="Context Verification">Context Verification</option>
              </select>
            </div>

            {/* For Continuous Reward */}
            {selectedEvaluatorType === "Continuous Reward" && (
              <>
                <div className="mt-4">
                  <label className="label">
                    <span className="label-text">Evaluator Criteria</span>
                  </label>
                  <div className="flex items-center space-x-2">
                    <select
                      value={selectedRewardType}
                      onChange={(e) => setSelectedRewardType(e.target.value)}
                      className="select select-bordered"
                      disabled={isEvaluating}
                    >
                      <option value="" disabled>
                        Choose reward type
                      </option>
                      <option value="Reward responses">Reward responses</option>
                      <option value="Penalize responses">Penalize responses</option>
                    </select>
                    <input
                      type="text"
                      placeholder="Enter evaluator criteria"
                      className="input input-bordered flex-1"
                      value={newColumnName}
                      onChange={(e) => setNewColumnName(e.target.value)}
                      disabled={isEvaluating}
                    />
                  </div>
                </div>
                {/* Display the concatenated Evaluator Name */}
                {selectedRewardType && newColumnName.trim() && (
                  <div className="mt-2">
                    <label className="label">
                      <span className="label-text">Evaluator Name</span>
                    </label>
                    <input
                      type="text"
                      className="input input-bordered w-full"
                      value={`${selectedRewardType} ${newColumnName.trim()}`}
                      readOnly
                    />
                  </div>
                )}
              </>
            )}

            {/* For Binary */}
            {selectedEvaluatorType === "Binary" && (
              <>
                <div className="mt-4">
                  <label className="label">
                    <span className="label-text">Evaluator Criteria</span>
                  </label>
                  <div className="flex items-center space-x-2">
                    <select
                      value={selectedBinaryType}
                      onChange={(e) => setSelectedBinaryType(e.target.value)}
                      className="select select-bordered"
                      disabled={isEvaluating}
                    >
                      <option value="" disabled>
                        Choose binary type
                      </option>
                      <option value="Response passes if">Response passes if</option>
                      <option value="Response fails if">Response fails if</option>
                    </select>
                    <input
                      type="text"
                      placeholder="Enter evaluator criteria"
                      className="input input-bordered flex-1"
                      value={newColumnName}
                      onChange={(e) => setNewColumnName(e.target.value)}
                      disabled={isEvaluating}
                    />
                  </div>
                </div>
                {/* Display the concatenated Evaluator Name */}
                {selectedBinaryType && newColumnName.trim() && (
                  <div className="mt-2">
                    <label className="label">
                      <span className="label-text">Evaluator Name</span>
                    </label>
                    <input
                      type="text"
                      className="input input-bordered w-full"
                      value={`${selectedBinaryType} ${newColumnName.trim()}`}
                      readOnly
                    />
                  </div>
                )}
              </>
            )}

            {/* For Context Verification */}
            {selectedEvaluatorType === "Context Verification" && (
              <div className="mt-4">
                <label className="label">
                  <span className="label-text">Select Context Verification Type</span>
                </label>
                <select
                  value={selectedContextVerificationOption}
                  onChange={(e) => setSelectedContextVerificationOption(e.target.value)}
                  className="select select-bordered w-full"
                  disabled={isEvaluating}
                >
                  <option value="" disabled>
                    Choose option
                  </option>
                  <option value="Context Faithfulness">Context Faithfulness</option>
                  <option value="Context Recall">Context Recall</option>
                  <option value="Context Precision">Context Precision</option>
                </select>
              </div>
            )}

            {/* Modal Actions */}
            <div className="modal-action">
              <button
                className="btn btn-primary"
                onClick={() => {
                  let columnName = "";
                  let criteria = "";

                  if (selectedEvaluatorType === "Continuous Reward") {
                    if (!selectedRewardType) {
                      alert("Please select Reward type.");
                      return;
                    }
                    if (!newColumnName.trim()) {
                      alert("Evaluator criteria cannot be empty.");
                      return;
                    }
                    criteria = newColumnName.trim();
                    columnName = `${selectedRewardType} ${criteria}`;
                  } else if (selectedEvaluatorType === "Binary") {
                    if (!selectedBinaryType) {
                      alert("Please select Binary type.");
                      return;
                    }
                    if (!newColumnName.trim()) {
                      alert("Evaluator criteria cannot be empty.");
                      return;
                    }
                    criteria = newColumnName.trim();
                    columnName = `${selectedBinaryType} ${criteria}`;
                  } else if (selectedEvaluatorType === "Context Verification") {
                    if (!selectedContextVerificationOption) {
                      alert("Please select Context Verification option.");
                      return;
                    }
                    criteria = selectedContextVerificationOption;
                    columnName = selectedContextVerificationOption;
                  }

                  if (!columnName) {
                    alert("Evaluator name cannot be empty.");
                    return;
                  }

                  onAddColumn(columnName, criteria);

                  // Reset modal state
                  setNewColumnName("");
                  setSelectedEvaluatorType("");
                  setSelectedContextVerificationOption("");
                  setSelectedRewardType("");
                  setSelectedBinaryType("");
                  setIsAddColumnModalOpen(false);
                }}
                disabled={
                  isEvaluating ||
                  !selectedEvaluatorType ||
                  (selectedEvaluatorType === "Continuous Reward" && (!selectedRewardType || !newColumnName.trim())) ||
                  (selectedEvaluatorType === "Binary" && (!selectedBinaryType || !newColumnName.trim())) ||
                  (selectedEvaluatorType === "Context Verification" && !selectedContextVerificationOption)
                }
              >
                Add
              </button>
              <button
                className="btn"
                onClick={() => {
                  setIsAddColumnModalOpen(false);
                  setNewColumnName("");
                  setSelectedEvaluatorType("");
                  setSelectedContextVerificationOption("");
                  setSelectedRewardType("");
                  setSelectedBinaryType("");
                }}
                disabled={isEvaluating}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Export Modal */}
      {exportModalOpen && (
        <div className="modal modal-open transition-opacity duration-300 ease-in-out">
          <div className="modal-box rounded-lg shadow-lg bg-white">
            <h3 className="font-bold text-lg mb-4">Choose Export Format</h3>
            <p className="text-sm text-gray-600 mb-4">Select the file format you would like to export your data in:</p>
            <div className="space-y-3">
              <label className="flex items-center space-x-3">
                <input
                  type="radio"
                  name="exportFormat"
                  className="radio radio-primary"
                  checked={selectedExportType === "jsonl"}
                  onChange={() => setSelectedExportType("jsonl")}
                  disabled={isEvaluating}
                />
                <span className="font-medium">JSONL</span>
              </label>
              <label className="flex items-center space-x-3">
                <input
                  type="radio"
                  name="exportFormat"
                  className="radio radio-primary"
                  checked={selectedExportType === "xlsx"}
                  onChange={() => setSelectedExportType("xlsx")}
                  disabled={isEvaluating}
                />
                <span className="font-medium">XLSX</span>
              </label>
              <label className="flex items-center space-x-3">
                <input
                  type="radio"
                  name="exportFormat"
                  className="radio radio-primary"
                  checked={selectedExportType === "csv"}
                  onChange={() => setSelectedExportType("csv")}
                  disabled={isEvaluating}
                />
                <span className="font-medium">CSV</span>
              </label>
            </div>
            <div className="modal-action mt-6">
              <button
                className="btn btn-primary"
                onClick={() => {
                  if (selectedExportType === "jsonl") {
                    onExportJSONL();
                  } else if (selectedExportType === "csv") {
                    onExportCSV();
                  }else {
                    onExportXLSX();
                  }
                  setExportModalOpen(false);
                }}
                disabled={isEvaluating}
              >
                Export
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setExportModalOpen(false)}
                disabled={isEvaluating}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="relative group">
        <button
          className="btn btn-sm btn-primary w-full"
          onClick={onEvaluateAll}
          disabled={isEvaluating || !hasEvaluatorColumns}
        >
          {isEvaluating ? "Evaluating..." : "Evaluate All"}
        </button>
        {!hasEvaluatorColumns && (
          <div
            className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 text-xs text-white bg-gray-900 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none min-w-[180px]">
            Please add at least one evaluator to evaluate
            <div className="absolute w-2 h-2 bg-gray-900 rotate-45 -bottom-1 left-1/2 -translate-x-1/2"></div>
          </div>
        )}
      </div>
    </div>
  );
};

// APIPlayground Component
const APIPlayground: React.FC = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<"data" | "explore">("data");

  // const [gridData, setGridData] = useState<RowData[]>([]);
  // const [rowWrapEnabled, setRowWrapEnabled] = useState(false);
  const [isInvalidEvaluatorModalOpen, setIsInvalidEvaluatorModalOpen] = useState(false);
  const [apiKey, setApiKey] = useState<string | null>(null);
  // const [runId, setRunId] = useState<string>(uuidv4());
  // const [fileName, setFileName] = useState<string | null>(null);
  const {
    gridData,
    columnDefs,
    rowWrapEnabled,
    fileName,
    runId,
    setGridData,
    setColumnDefs,
    setRowWrapEnabled,
    setFileName,
    setRunId,
    clearAll,
    updateColumnDefs
  } = useDataGrid();

  const CustomArrayTextEditor = (props: ICellEditorParams) => {
    const { value, node, column } = props;

    const initialValue = Array.isArray(value) ? value[0] : value;

    const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
      const newValue = event.target.value;

      const updatedValue = Array.isArray(value) ?
        [newValue, ...value.slice(1)] :
        [newValue, ""];

      props.api.stopEditing();
      node.setDataValue(column.getId(), updatedValue);
    };

    return (
      <textarea
        autoFocus
        defaultValue={initialValue}
        onBlur={handleBlur}
        style={{ width: "100%", height: "200px", resize: "vertical" }}
      />
    );
  };

  // State to store user ID and Email
  const [userId, setUserId] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [waitingForUserId, setWaitingForUserId] = useState(false);
  const [refreshHistoryStamp, setRefreshHistoryStamp] = useState(Date.now());
  // Use Context to provide user ID and Email globally
  const UserContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
      <UserContext.Provider value={{ userId, userEmail, setUserId, setUserEmail }}>
        {children}
      </UserContext.Provider>
    );
  };

  useEffect(() => {
    const tryEvaluate = async () => {
      if (waitingForUserId && userId && runId) {
        setWaitingForUserId(false);
        await performEvaluateAll();
      }
    };

    tryEvaluate();
  }, [waitingForUserId, userId, runId]);

  useEffect(() => {
    const fetchApiKeyAndTestConnection = async () => {
      try {
        let keys = await listApiKey();
        if (keys.length === 0) {
          // Generate a new API key if none exist
          await makeApiRequestPromise({
            path: "/api/keys/create",
            method: "POST",
          });
          toast.success("Default API key Initialized for your account");
          keys = await listApiKey();
        }
        if (keys && keys.length > 0) {
          setApiKey(keys[0]);
          console.log(keys[0]);
          await testConnection(keys[0]);
        } else {
          throw new Error("No API keys available even after generation.");
        }
      } catch (error: any) {
        console.error("Failed to fetch or generate API Key:", error);
        toast.error("Failed to fetch or generate API Key");
      }
    };

    const testConnection = async (key: string) => {
      console.log("Testing API connection...");

      try {
        const result = await makeEvaluateRequest(
          "Hi, how are you?",  // input
          "Hi, it's great to meet you! How are you doing today?",  // response
          "",  // context
          "",  // reference
          "Reward responses that demonstrate overall quality",  // evaluatorName
          "test_connection",  // runId
          key  // apiKey
        );

        if (result.score === undefined || result.score === null) {
          toast.error("API connection failed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.success("API connected successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } catch (error) {
        toast.error("API connection failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    };
    fetchApiKeyAndTestConnection();
  }, []);

  useEffect(() => {
    const fetchUserIdAndEmail = async () => {
      try {
        // Fetch user email from authentication session
        const session = await fetchAuthSession();
        const email = session.tokens?.idToken?.payload.email;

        if (email) {
          const id = await fetchUserIdByEmail(String(email));
          setUserId(id);
        } else {
          throw new Error("User email not found in authentication session.");
        }
      } catch (error: any) {
        console.error(error);
        toast.error("An error occurred while fetching user ID and email.");
      }
    };

    fetchUserIdAndEmail();
  }, []);

  const onClearAll = () => {
    setGridData([]);
    setColumnDefs(getInitialColumnDefs(rowWrapEnabled));
  };

  // Function to generate initial column definitions based on rowWrapEnabled
  const getInitialColumnDefs = (wrapText: boolean): ColDef[] => {
    const baseWidth = 150;
    const specialWidth = baseWidth * 3;

    return DEFAULT_COLUMNS.map((col) => ({
      field: col,
      headerTooltip: col,
      sortable: true,
      filter: true,
      cellEditor: CustomArrayTextEditor,
      cellEditorPopup: true,
      wrapText: wrapText,
      autoHeight: wrapText,
      cellStyle: { whiteSpace: wrapText ? "pre-wrap" : "nowrap" },

      minWidth: col === 'Input' || col === 'Response' ? specialWidth : baseWidth,
      width: col === 'Input' || col === 'Response' ? specialWidth : undefined,
    }));
  };

  // Initialize columnDefs with default columns
  const hasEvaluatorColumns = useMemo(
    () => columnDefs.some(col => !DEFAULT_COLUMNS_LOWER.includes(col.field!.toLowerCase())),
    [columnDefs]
  );

  const gridRef = useRef<AgGridReact<RowData>>(null);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const [isEvaluating, setIsEvaluating] = useState(false);
  const [isUploadingFinish, setIsUploadingFinish] = useState(false);
  const [evaluators, setEvaluators] = useState<EvaluatorResponse[]>([]);
  const [missingEvaluators, setMissingEvaluators] = useState<EvaluatorResponse[]>([]);
  const [missingEvaluatorCriteria, setMissingEvaluatorCriteria] = useState<string[]>([]);
  const [isEvaluatorLoaded, setIsEvaluatorLoaded] = useState<boolean>(false);
  const [isMissingEvaluatorModalOpen, setIsMissingEvaluatorModalOpen] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState<string>("");
  const handleGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };
  const { setCriteriaToNameMap } = useDataGrid();
  useEffect(() => {
    const criteriaToName = new Map<string, string>();
    const nameToCriteria = new Map<string, string>();

    evaluators.forEach(e => {
      criteriaToName.set(e.evaluate_criteria, e.evaluator_name);
      nameToCriteria.set(e.evaluator_name, e.evaluate_criteria);
    });
    setCriteriaToNameMap(criteriaToName)
  }, [evaluators]);

  const [criteriaToNameMap, nameToCriteriaMap] = useMemo(() => {
    const criteriaToName = new Map<string, string>();
    const nameToCriteria = new Map<string, string>();

    evaluators.forEach(e => {
      criteriaToName.set(e.evaluate_criteria, e.evaluator_name);
      nameToCriteria.set(e.evaluator_name, e.evaluate_criteria);
    });
    return [criteriaToName, nameToCriteria];
  }, [evaluators]);

  const handleCellValueChanged = (params: CellValueChangedEvent) => {
    const updatedData = gridData.map((row) => {
      if (row.id === params.data.id) {
        return { ...row, [params.colDef.field!]: params.newValue };
      }
      return row;
    });
    setGridData(updatedData);
  };

  const deleteSelectedRows = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows();
    if (selectedRows && selectedRows.length > 0) {
      const idsToDelete = selectedRows.map((row) => row.id);
      setGridData((prevData: RowData[]) =>
        prevData.filter((row) => !idsToDelete.includes(row.id))
      );}
  };

  const addRow = () => {
    const newId = gridData.length > 0 ? Math.max(...gridData.map((row) => row.id)) + 1 : 1;
    const newRow: RowData = { id: newId };
    // Initialize all columns with empty strings
    columnDefs.forEach((col) => {
      if (col.field) {
        newRow[col.field] = "";
      }
    });
    setGridData((prevData) => [...prevData, newRow]);
  };
  const deleteSelectedColumn = () => {
    deleteColumn(selectedColumn);
  }

  const deleteColumn = (fieldToDelete: string) => {
    if (DEFAULT_COLUMNS_LOWER.includes(fieldToDelete.toLowerCase())) {
      // Prevent deletion of default columns
      alert(`The column "${fieldToDelete}" cannot be deleted.`);
      return;
    }
    setColumnDefs((prevDefs) => prevDefs.filter((col) => col.field?.toLowerCase() !== fieldToDelete.toLowerCase()));
    setGridData((prevData) =>
      prevData.map((row) => {
        const updatedRow = { ...row };
        delete updatedRow[fieldToDelete];
        return updatedRow;
      }),
    );
  };

  const onAddColumn = (evaluatorName: string, criteria: string) => {
    const newColumnName = evaluatorName.trim();
    if (!newColumnName) {
      alert("Column name cannot be empty.");
      return;
    }
    if (!criteria) {
      alert("criteria cannot be empty.");
      return;
    }
    const existingFieldsLower = columnDefs.map((col) => col.field!.toLowerCase());
    if (
      existingFieldsLower.includes(newColumnName.toLowerCase()) ||
      DEFAULT_COLUMNS_LOWER.includes(newColumnName.toLowerCase())
    ) {
      alert("Column name already exists or is reserved.");
      return;
    }
    updateColumnDefs((prevDefs) => [
      ...prevDefs,
      {
        field: criteria,
        headerTooltip: newColumnName,
        sortable: true,
        filter: true,
        cellDataType: 'text',
        cellEditor: CustomArrayTextEditor,
        cellEditorPopup: true,
        wrapText: rowWrapEnabled,
        autoHeight: rowWrapEnabled,
      },
    ]);
    setGridData((prevData) =>
      prevData.map((row) => ({
        ...row,
        [newColumnName]: "",
      })),
    );
  };

  const onClearColumn = (field: string) => {
    setGridData((prevData) => prevData.map((row) => ({ ...row, [field]: null })));
  };

  const BATCH_SIZE = 4;

  const onEvaluateColumn = async (evaluatorName: string) => {
    if (isEvaluating) return;
    setIsEvaluating(true);

    const rowsToEvaluate = gridData
      .map((row, index) => ({ row, index }))
      .filter(({ row }) => {
        const val = row[evaluatorName];
        console.log("valq")
        console.log(val)
        // Handle null/undefined/empty string
        if (val == null || val === "") {
          return true;
        }

        // Handle array values
        if (Array.isArray(val)) {
          if (val.length === 0) {
            return true;
          }
          if (val[0] == null || val[0] === "") {
            return true;
          }
        }

        return false;
      });

    // Process in batches
    for (let i = 0; i < rowsToEvaluate.length; i += BATCH_SIZE) {
      const batch = rowsToEvaluate.slice(i, i + BATCH_SIZE);

      const batchPromises = batch.map(({ row, index }) =>
        evaluate(row["Input"], row["Response"], row["Context"], row["Reference"], evaluatorName)
          .then(result => ({ result, index }))
          .catch(error => {
            console.error(`Error evaluating row ${index}:`, error);
            return { result: { score: null, detail: "Evaluation failed" }, index };
          })
      );

      const results = await Promise.all(batchPromises);

      // Update grid data with batch results
      setGridData(prevData => {
        const newData = [...prevData];
        results.forEach(({ result, index }) => {
          if (result.score !== null && result.score !== undefined) {
            newData[index] = { ...newData[index], [evaluatorName]: result.score };
          } else if (result.detail) {
            newData[index] = { ...newData[index], [evaluatorName]: result.detail };
          }
        });
        return newData;
      });
    }

    setIsEvaluating(false);
  };

  const onEvaluateAll = async () => {
    // const evaluatorColumns = columnDefs
    //   .filter(col => col.field && !DEFAULT_COLUMNS_LOWER.includes(col.field.toLowerCase()))
    //   .map(col => col.field!);
    //
    // const datagridEvaluatorCriteria = columnDefs
    //   .filter(col => col.field && !DEFAULT_COLUMNS_LOWER.includes(col.field.toLowerCase()))
    //   .map(col => col.field!.trim().toLowerCase());
    //
    // const evaluatorCriteria = evaluators
    //   .map(e => e.evaluate_criteria)
    //   .filter(Boolean)
    //   .map(criterion => criterion.trim().toLowerCase());
    //
    // const missingEvaluatorCriteria = datagridEvaluatorCriteria.filter(
    //   criteria => !evaluatorCriteria.includes(criteria)
    // );
    // console.log("missingEvaluatorCriteria", missingEvaluatorCriteria);
    // setMissingEvaluatorCriteria(missingEvaluatorCriteria);
    // if (missingEvaluatorCriteria.length > 0) {
    //   setIsMissingEvaluatorModalOpen(true);
    // }

    if (isEvaluating) return;
    setIsEvaluating(true);
    setRunId(uuidv4());
    if (userId && runId) {
      await performEvaluateAll();
    } else {
      setWaitingForUserId(true);
    }
  };

  const performEvaluateAll = async () => {
    console.log("Performing evaluate all...");
    await createRun(runId, fileName);
    await processAndSendInBatches(runId, userId!, gridData, import.meta.env.VITE_PLATFORM_BACKEND_API_URL)
      .then(() => {
        setIsUploadingFinish(true);
        console.log("All data uploaded successfully.");
        toast.success("All data uploaded successfully.");
      })
      .catch(error => {
        console.error("Error when uploading:", error);
        toast.error("Error when uploading: " + error);
        throw error;
      });
    setRefreshHistoryStamp(Date.now());
    const evaluatorColumns = columnDefs
      .filter((col) => !DEFAULT_COLUMNS_LOWER.includes(col.field!.toLowerCase()))
      .map((col) => col.field!);

    for (const evaluator of evaluatorColumns) {
      // Filter rows that need evaluation for this evaluator
      const rowsToEvaluate = gridData
        .map((row, index) => ({ row, index }))
        .filter(({ row }) => {
          const val = row[evaluator];
          console.log("valq")
          console.log(val)
          // Handle null/undefined/empty string
          if (val == null || val === "") {
            return true;
          }

          // Handle array values
          if (Array.isArray(val)) {
            if (val.length === 0) {
              return true;
            }
            if (val[0] == null || val[0] === "") {
              return true;
            }
          }

          return false;
        });

      // Process in batches
      for (let i = 0; i < rowsToEvaluate.length; i += BATCH_SIZE) {
        const batch = rowsToEvaluate.slice(i, i + BATCH_SIZE);

        const batchPromises = batch.map(({ row, index }) =>
          evaluate(row["Input"], row["Response"], row["Context"], row["Reference"], evaluator)
            .then(result => ({ result, index }))
            .catch(error => {
              console.error(`Error evaluating row ${index}, evaluator ${evaluator}:`, error);
              return { result: { score: null, detail: "Evaluation failed" }, index };
            })
        );

        const results = await Promise.all(batchPromises);

        // Update grid data with batch results
        setGridData(prevData => {
          const newData = [...prevData];
          results.forEach(({ result, index }) => {
            if (result.score !== null && result.score !== undefined) {
              newData[index] = { ...newData[index], [evaluator]: [result.score, result.explanation || ""] };
            } else if (result.detail) {
              newData[index] = { ...newData[index], [evaluator]: result.detail };
            } else if (result.score == null) {
              newData[index] = { ...newData[index], [evaluator]: "N/A" };
            }
          });
          return newData;
        });
      }
    }
    setIsEvaluating(false);
    toast.success(
      <div className="flex items-center">
        <LightBulbIcon className="w-5 h-5 mr-2" />
        <span>
            <div>Evaluation completed!</div>
          <span className="underline cursor-pointer">View Insights</span>
          </span>
      </div>,
      {
        autoClose: 5000,
        closeOnClick: false,
        onClick: () => {
          navigate("/insights", {
            state: {
              fromEvaluation: true,
              runId: runId
            }
          });
        },
        bodyClassName: "cursor-pointer",
        className: "border-l-4 bg-primary",
        icon: false
      }
    );
  };

  interface EvaluationResult {
    score: number | null;
    detail?: string;
    explanation?: string;
  }

  // Helper function to create consistent request options
  const createRequestOptions = (payload: any): RequestInit => {
    const myHeaders = new Headers();
    if (apiKey == null) {
      throw new Error("No API keys available even after generation.");
    }
    myHeaders.append("API-Key", apiKey);
    myHeaders.append("Content-Type", "application/json");

    let body;
    try {
      body = JSON.stringify(payload);
      console.log("Request payload:", body);
    } catch (error) {
      console.error("Error stringifying payload:", error);
      throw error;
    }

    return {
      method: "POST",
      headers: myHeaders,
      body: body,
      redirect: "follow" as RequestRedirect
    };
  };

  const evaluate = async (
    input: string,
    response: string,
    context: string,
    reference: string,
    evaluatorName: string,
  ): Promise<EvaluationResult> => {
    try {
      if (typeof response !== "string" || response.trim().length === 0) {
        console.error("Invalid response:", response);
        return {
          score: null,
          detail: "Response is invalid or empty.",
        };
      }
      if (apiKey == null) {
        throw new Error("No API keys available");
      }
      console.log("Evaluating input:", input);
      return await makeEvaluateRequest(input, response, context, reference, evaluatorName, runId, apiKey);
    } catch (error: any) {
      console.error(`Evaluation error for evaluator "${evaluatorName}":`, error);
      return {
        score: null,
        detail: `Error: ${error.message || 'An unexpected error occurred'}`,
      };
    }
  };

  const handleExportJSONL = () => {
    if (gridData.length === 0) {
      console.error("No data to export");
      return;
    }

    const dataToExport = gridData.map(({ id, ...rest }) => {
      const transformedRow: Record<string, any> = {};
      Object.entries(rest).forEach(([key, value]) => {
        transformedRow[key] = value !== undefined ? value : '';
      });
      return transformedRow;
    });

    const jsonlContent = dataToExport.map((row) => JSON.stringify(row)).join("\n");

    const blob = new Blob([jsonlContent], { type: "application/jsonl" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "data.jsonl";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };


  const handleExportXLSX = () => {
    if (gridData.length === 0) {
      console.error("No data to export");
      return;
    }

    let headers: string[] = [];
    let explanationHeaders: string[] = [];
    let keyOrderMap: Record<string, number> = {};

    gridData.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (key === "id") return;
        if (!(key in keyOrderMap)) {
          keyOrderMap[key] = headers.length;
          headers.push(key);
        }
        if (Array.isArray(row[key]) && row[key].length > 1) {
          const explanationKey = `explanation_${key}`;
          if (!(explanationKey in keyOrderMap)) {
            explanationHeaders.push(explanationKey);
            keyOrderMap[explanationKey] = keyOrderMap[key] + 1;
          }
        }
      });
    });

    headers = headers.reduce((sortedHeaders, key) => {
      sortedHeaders.push(key);
      if (explanationHeaders.includes(`explanation_${key}`)) {
        sortedHeaders.push(`explanation_${key}`);
      }
      return sortedHeaders;
    }, [] as string[]);

    const dataToExport = gridData.map((row) => {
      const transformedRow: Record<string, any> = {};

      headers.forEach((header) => {
        if (header.startsWith("explanation_")) {
          const originalKey = header.replace("explanation_", "");
          const value = row[originalKey];
          transformedRow[header] = Array.isArray(value) && value.length > 1 ? value[1] : "";
        } else {
          const value = row[header];
          transformedRow[header] = Array.isArray(value) ? value[0] : value != null ? String(value) : "";
        }
      });

      return transformedRow;
    });

    const worksheet = XLSX.utils.json_to_sheet([headers], { skipHeader: true });
    XLSX.utils.sheet_add_json(worksheet, dataToExport, { origin: "A2", skipHeader: true });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    const xlsxContent = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    const blob = new Blob([xlsxContent], { type: "application/octet-stream" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "data.xlsx");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("File download not supported in this browser.");
    }
  };

  const handleExportCSV = () => {
    if (gridData.length === 0) {
      console.error("No data to export");
      return;
    }

    let headers: string[] = [];
    let explanationHeaders: string[] = [];
    let keyOrderMap: Record<string, number> = {};

    gridData.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (key === "id") return;
        if (!(key in keyOrderMap)) {
          keyOrderMap[key] = headers.length;
          headers.push(key);
        }
        if (Array.isArray(row[key]) && row[key].length > 1) {
          const explanationKey = `explanation_${key}`;
          if (!(explanationKey in keyOrderMap)) {
            explanationHeaders.push(explanationKey);
            keyOrderMap[explanationKey] = keyOrderMap[key] + 1;
          }
        }
      });
    });

    headers = headers.reduce((sortedHeaders, key) => {
      sortedHeaders.push(key);
      if (explanationHeaders.includes(`explanation_${key}`)) {
        sortedHeaders.push(`explanation_${key}`);
      }
      return sortedHeaders;
    }, [] as string[]);

    const dataToExport = gridData.map((row) => {
      const transformedRow: Record<string, any> = {};

      headers.forEach((header) => {
        if (header.startsWith("explanation_")) {
          const originalKey = header.replace("explanation_", "");
          const value = row[originalKey];
          transformedRow[header] = Array.isArray(value) && value.length > 1 ? value[1] : "";
        } else {
          const value = row[header];
          transformedRow[header] = Array.isArray(value) ? value[0] : value != null ? String(value) : "";
        }
      });

      return transformedRow;
    });

    const csvContent = Papa.unparse({
      fields: headers,
      data: dataToExport
    }, {
      quotes: true,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\n"
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      alert("File download not supported in this browser.");
    }
  };

  const isValidEvaluatorFormat = (columnName: string): boolean => {
    const lowerCaseName = columnName.toLowerCase();
    return (
      lowerCaseName.startsWith("response passes if") ||
      lowerCaseName.startsWith("response fails if") ||
      lowerCaseName.startsWith("reward responses") ||
      lowerCaseName.startsWith("penalize responses")
    );
  };

  const handleImportJSONL = (file: File) => {
    const fileName = file.name
      .replace(/\.[^/.]+$/, "")
      .replace(/[^a-zA-Z0-9-_ ]/g, "")
      .trim()
      .substring(0, 50);
    setFileName(fileName);
    const reader = new FileReader();
    reader.onload = function (e) {
      const content = e.target?.result;
      if (typeof content === "string") {
        const lines = content.split("\n").filter((line) => line.trim() !== "");
        const data: RowData[] = [];
        const fieldsSet = new Set<string>();

        const standardColumnsMap = new Map<string, string>();
        DEFAULT_COLUMNS.forEach((col) => {
          standardColumnsMap.set(col.toLowerCase(), col);
        });

        for (let i = 0; i < lines.length; i++) {
          try {
            const parsedLine = JSON.parse(lines[i]);
            const standardizedLine: any = {};
            Object.keys(parsedLine).forEach((key) => {
              const lowerKey = key.toLowerCase();
              const standardKey = standardColumnsMap.get(lowerKey) || key;
              standardizedLine[standardKey] = parsedLine[key];
            });

            data.push({ id: i + 1, ...standardizedLine });
            Object.keys(standardizedLine).forEach((key) => fieldsSet.add(key));
          } catch (error) {
            console.error(`Error parsing line ${i + 1}:`, error);
            alert(`Error parsing JSONL file at line ${i + 1}.`);
            return;
          }
        }

        const fields = Array.from(fieldsSet);
        const fieldsLower = fields.map((f) => f.toLowerCase());

        const missingColumns = REQUIRED_COLUMNS.filter(
          (col) => !fieldsLower.includes(col.toLowerCase()),
        );

        if (missingColumns.length > 0) {
          alert(`Error: Missing required columns: ${missingColumns.join(", ")}`);
          return;
        }

        const invalidEvaluators = fields.filter(
          field => !DEFAULT_COLUMNS_LOWER.includes(field.toLowerCase()) && !isValidEvaluatorFormat(field)
        );

        const validEvaluators = fields.filter(
          field => !DEFAULT_COLUMNS_LOWER.includes(field.toLowerCase()) && isValidEvaluatorFormat(field)
        );

        // Determine which default columns are missing
        const defaultColumnsToAdd = DEFAULT_COLUMNS.filter(
          (col) => !fields.map((f) => f.toLowerCase()).includes(col.toLowerCase()),
        );

        // Build column definitions, only including valid format evaluators
        const newColumns: ColDef[] = [
          // Include default columns
          ...DEFAULT_COLUMNS.map((col) => ({
            field: col,
            headerTooltip: col,
            sortable: true,
            filter: true,
            cellEditor: CustomArrayTextEditor,
            cellEditorPopup: true,
            wrapText: rowWrapEnabled,
            autoHeight: rowWrapEnabled,
          })),
          // Add only valid format evaluator fields
          ...validEvaluators.map((field) => {
            return {
              field: field,
              headerTooltip: field,
              sortable: true,
              filter: true,
              cellEditor: CustomArrayTextEditor,
              cellEditorPopup: true,
              wrapText: rowWrapEnabled,
              autoHeight: rowWrapEnabled,
            };
          }),
        ];

        // Remove duplicate columns while preserving properties
        const uniqueColumnsMap = new Map<string, ColDef>();
        newColumns.forEach((col) => {
          const fieldLower = col.field!.toLowerCase();
          if (!uniqueColumnsMap.has(fieldLower)) {
            uniqueColumnsMap.set(fieldLower, {
              ...col,
              headerTooltip: col.headerTooltip || col.field,
            });
          } else {
            // Merge properties of duplicate columns
            uniqueColumnsMap.set(fieldLower, {
              ...uniqueColumnsMap.get(fieldLower)!,
              ...col,
              headerTooltip: col.headerTooltip || col.field,
            });
          }
        });
        const uniqueColumns = Array.from(uniqueColumnsMap.values());

        updateColumnDefs(uniqueColumns);

        const updatedData = data.map((rowData) => {
          const cleanedRow: any = { id: rowData.id };

          // Add all default columns
          DEFAULT_COLUMNS.forEach(col => {
            cleanedRow[col] = col in rowData ? rowData[col] : "";
          });

          validEvaluators.forEach(key => {
            if (key in rowData) {
              cleanedRow[key] = rowData[key];
            }
          });

          return cleanedRow;
        });

        setGridData(updatedData);

        if (invalidEvaluators.length > 0) {
          setMissingEvaluatorCriteria(invalidEvaluators);
          setIsInvalidEvaluatorModalOpen(true);
        }
      }
    };
    reader.onerror = function (e) {
      console.error("File reading error:", e);
      alert("Error reading JSONL file.");
    };
    reader.readAsText(file);
  };

  const handleImportXLSX = (file: File) => {
    const fileName = file.name
      .replace(/\.[^/.]+$/, "")
      .replace(/[^a-zA-Z0-9-_ ]/g, "")
      .trim()
      .substring(0, 50);
    setFileName(fileName);
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Create a mapping of lowercase standard column names to their correct casing
      const standardColumnsMap = new Map<string, string>();
      DEFAULT_COLUMNS.forEach((col) => {
        standardColumnsMap.set(col.toLowerCase(), col);
      });

      const rawData = XLSX.utils.sheet_to_json<RowData>(worksheet, { defval: "" });

      if (rawData.length === 0) {
        alert("The XLSX file is empty.");
        return;
      }

      // Standardize column names in the data
      const jsonData = rawData.map((rowData, index) => {
        const standardizedRow: any = {};
        const keys = Object.keys(rowData);

        for (let i = 0; i < keys.length; i++) {
          const currentKey = keys[i];
          const nextKey = keys[i + 1];

          const currentLowerKey = currentKey.toLowerCase();
          const nextLowerKey = nextKey?.toLowerCase();

          const standardCurrentKey = standardColumnsMap.get(currentKey.toLowerCase()) || currentKey;

          if (nextKey && nextKey.toLowerCase().startsWith("explanation_")) {
            standardizedRow[standardColumnsMap.get(currentKey.toLowerCase()) || currentKey] = [rowData[currentKey], rowData[nextKey]];
            i++;
          } else if (!currentKey.toLowerCase().startsWith("explanation_")) {
            standardizedRow[standardColumnsMap.get(currentKey.toLowerCase()) || currentKey] = rowData[currentKey];
          } else {
            standardizedRow[standardColumnsMap.get(currentKey.toLowerCase()) || currentKey] = rowData[currentKey];
          }
        }

        return standardizedRow;
      });

      const fields = Object.keys(jsonData[0]);
      const fieldsLower = fields.map((f) => f.toLowerCase());
      const fieldsLowerMap = new Map(fields.map(f => [f.toLowerCase(), f]));

      // Check for missing required columns (case-insensitive)
      const missingColumns = REQUIRED_COLUMNS.filter(
        (col) => !fieldsLower.includes(col.toLowerCase()),
      );

      if (missingColumns.length > 0) {
        alert(`Error: Missing required columns: ${missingColumns.join(", ")}`);
        return;
      }

      const invalidEvaluators = fields.filter(
        field => !DEFAULT_COLUMNS_LOWER.includes(field.toLowerCase()) && !isValidEvaluatorFormat(field)
      );

      const validEvaluators = fields.filter(
        field => !DEFAULT_COLUMNS_LOWER.includes(field.toLowerCase()) && isValidEvaluatorFormat(field)
      );

      // Determine which default columns are missing
      const defaultColumnsToAdd = DEFAULT_COLUMNS.filter(
        (col) => !fields.map((f) => f.toLowerCase()).includes(col.toLowerCase()),
      );

      // Build column definitions - only including valid evaluator columns
      const newColumns: ColDef[] = [
        // Include default columns
        ...DEFAULT_COLUMNS.map((col) => ({
          field: col,
          headerTooltip: col,
          sortable: true,
          filter: true,
          cellEditor: CustomArrayTextEditor,
          cellEditorPopup: true,
          wrapText: rowWrapEnabled,
          autoHeight: rowWrapEnabled,
        })),
        // Add only valid format evaluator columns
        ...validEvaluators.map((field) => {
          return {
            field: field,
            headerTooltip: field,
            sortable: true,
            filter: true,
            cellEditor: CustomArrayTextEditor,
            cellEditorPopup: true,
            wrapText: rowWrapEnabled,
            autoHeight: rowWrapEnabled,
          };
        }),
      ];

      // Remove duplicate columns while preserving properties
      const uniqueColumnsMap = new Map<string, ColDef>();
      newColumns.forEach((col) => {
        const fieldLower = col.field!.toLowerCase();
        if (!uniqueColumnsMap.has(fieldLower)) {
          uniqueColumnsMap.set(fieldLower, {
            ...col,
            headerTooltip: col.headerTooltip || col.field,
          });
        } else {
          // Merge properties of duplicate columns
          uniqueColumnsMap.set(fieldLower, {
            ...uniqueColumnsMap.get(fieldLower)!,
            ...col,
            headerTooltip: col.headerTooltip || col.field,
          });
        }
      });
      const uniqueColumns = Array.from(uniqueColumnsMap.values());

      updateColumnDefs(uniqueColumns);

      const updatedData = jsonData.map((rowData, index) => {
        const cleanedRow: any = {};

        // Add all default columns
        DEFAULT_COLUMNS.forEach(col => {
          cleanedRow[col] = col in rowData ? rowData[col] : "";
        });

        validEvaluators.forEach(field => {
          if (field in rowData) {
            cleanedRow[field] = rowData[field];
            if (cleanedRow[field] === "") {
              cleanedRow[field] = null;
            }
          }
        });

        return { id: index + 1, ...cleanedRow };
      });

      setGridData(updatedData);

      if (invalidEvaluators.length > 0) {
        setMissingEvaluatorCriteria(invalidEvaluators);
        setIsInvalidEvaluatorModalOpen(true);
      }
    };
    reader.onerror = function (e) {
      console.error("File reading error:", e);
      alert("Error reading XLSX file.");
    };
    reader.readAsArrayBuffer(file);
  };

  const handleImportCSV = (file: File) => {
    const fileName = file.name
      .replace(/\.[^/.]+$/, "")
      .replace(/[^a-zA-Z0-9-_ ]/g, "")
      .trim()
      .substring(0, 50);
    setFileName(fileName);

    const standardColumnsMap = new Map<string, string>();
    DEFAULT_COLUMNS.forEach((col) => {
      standardColumnsMap.set(col.toLowerCase(), col);
    });

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      complete: (results) => {
        const rawData = results.data as any[];

        if (rawData.length === 0) {
          alert("The CSV file is empty.");
          return;
        }

        // Standardize column names in the data
        const jsonData = rawData.map((rowData) => {
          const standardizedRow: any = {};
          const keys = Object.keys(rowData);

          for (let i = 0; i < keys.length; i++) {
            const currentKey = keys[i];
            const nextKey = keys[i + 1];

            const currentLowerKey = currentKey.toLowerCase();
            const nextLowerKey = nextKey?.toLowerCase();

            const standardCurrentKey = standardColumnsMap.get(currentLowerKey) || currentKey;

            if (nextKey && nextLowerKey.startsWith("explanation_")) {
              standardizedRow[standardCurrentKey] = [rowData[currentKey], rowData[nextKey]];
              i++; // Skip the explanation column in the next iteration
            } else if (!currentLowerKey.startsWith("explanation_")) {
              standardizedRow[standardCurrentKey] = rowData[currentKey];
            } else {
              standardizedRow[standardCurrentKey] = rowData[currentKey];
            }
          }

          return standardizedRow;
        });

        const fields = Object.keys(jsonData[0] || {});
        const fieldsLower = fields.map((f) => f.toLowerCase());

        // Check for missing required columns (case-insensitive)
        const missingColumns = REQUIRED_COLUMNS.filter(
          (col) => !fieldsLower.includes(col.toLowerCase()),
        );

        if (missingColumns.length > 0) {
          alert(`Error: Missing required columns: ${missingColumns.join(", ")}`);
          return;
        }

        const invalidEvaluators = fields.filter(
          field => !DEFAULT_COLUMNS_LOWER.includes(field.toLowerCase()) && !isValidEvaluatorFormat(field)
        );

        const validEvaluators = fields.filter(
          field => !DEFAULT_COLUMNS_LOWER.includes(field.toLowerCase()) && isValidEvaluatorFormat(field)
        );

        // Build column definitions - only including valid evaluator columns
        const newColumns: ColDef[] = [
          // Include default columns
          ...DEFAULT_COLUMNS.map((col) => ({
            field: col,
            headerTooltip: col,
            sortable: true,
            filter: true,
            cellEditor: CustomArrayTextEditor,
            cellEditorPopup: true,
            wrapText: rowWrapEnabled,
            autoHeight: rowWrapEnabled,
          })),
          // Add only valid format evaluator columns
          ...validEvaluators.map((field) => ({
            field: field,
            headerTooltip: field,
            sortable: true,
            filter: true,
            cellEditor: CustomArrayTextEditor,
            cellEditorPopup: true,
            wrapText: rowWrapEnabled,
            autoHeight: rowWrapEnabled,
          })),
        ];

        // Remove duplicate columns while preserving properties
        const uniqueColumnsMap = new Map<string, ColDef>();
        newColumns.forEach((col) => {
          const fieldLower = col.field!.toLowerCase();
          if (!uniqueColumnsMap.has(fieldLower)) {
            uniqueColumnsMap.set(fieldLower, {
              ...col,
              headerTooltip: col.headerTooltip || col.field,
            });
          } else {
            // Merge properties of duplicate columns
            uniqueColumnsMap.set(fieldLower, {
              ...uniqueColumnsMap.get(fieldLower)!,
              ...col,
              headerTooltip: col.headerTooltip || col.field,
            });
          }
        });
        const uniqueColumns = Array.from(uniqueColumnsMap.values());

        updateColumnDefs(uniqueColumns);

        const updatedData = jsonData.map((rowData, index) => {
          const cleanedRow: any = {};

          // Add all default columns
          DEFAULT_COLUMNS.forEach(col => {
            cleanedRow[col] = col in rowData ? rowData[col] : "";
          });

          validEvaluators.forEach(field => {
            if (field in rowData) {
              cleanedRow[field] = rowData[field];
              if (cleanedRow[field] === "") {
                cleanedRow[field] = null;
              }
            }
          });

          return { id: index + 1, ...cleanedRow };
        });

        setGridData(updatedData);

        if (invalidEvaluators.length > 0) {
          setMissingEvaluatorCriteria(invalidEvaluators);
          setIsInvalidEvaluatorModalOpen(true);
        }
      },
      error: (error) => {
        console.error("CSV parsing error:", error);
        alert("Error parsing CSV file: " + error.message);
      }
    });
  };
  async function updateGridDataFromRun(runId: string) {
    try {
      const runData = await getRun(runId);
      if (!runData || !Array.isArray(runData.runs)) {
        console.warn("No valid run data found for runId:", runId);
        return;
      }

      const rawRows: RowData[] = runData.runs.map(({ run_id, ...row }: RowData) => row);

      console.log("rawRows", rawRows)
      const allFields = new Set<string>();
      rawRows.forEach((row: RowData) => {
        Object.keys(row).forEach(f => allFields.add(f));
      });

      DEFAULT_COLUMNS.forEach(col => allFields.add(col));

      const finalFields = [
        ...DEFAULT_COLUMNS,
        ...Array.from(allFields).filter(f =>
          !DEFAULT_COLUMNS.includes(f) &&
          !['id', 'run_id'].includes(f.toLowerCase())
        )
      ];

      const newColumnDefs: ColDef[] = finalFields.map((fieldName): ColDef => ({
        field: fieldName,
        headerTooltip: fieldName,
        sortable: true,
        filter: true,
        // valueFormatter: (params: ValueFormatterParams) => {
        //   const value = params.value;
        //   if (Array.isArray(value)) {
        //     return value.length > 0 ? String(value[0]) : '';
        //   }
        //   return value != null ? String(value) : '';
        // },
        tooltipValueGetter: (params: ITooltipParams) => {
          const value = params.value;
          return Array.isArray(value) && value.length > 1 ? value[1] : null;
        }
      }));

      setGridData(rawRows);
      setColumnDefs(newColumnDefs);

      console.log("Data with original columns:", rawRows);
      console.log("Column definitions:", newColumnDefs);
    } catch (error) {
      console.error(`Error updating grid data for run ${runId}:`, error);
    }
  }

  const onLoadSampleData = () => {
    const { gridData: sampleGridData, columnDefs: sampleColumnDefs } = getSampleData();

    // Include default columns
    const evaluatorColumns = sampleColumnDefs.filter(
      (colDef) => !DEFAULT_COLUMNS_LOWER.includes(colDef.field!.toLowerCase()),
    );

    const newColumnDefs: ColDef[] = [
      ...DEFAULT_COLUMNS.map((col) => ({
        field: col,
        headerTooltip: col,
        sortable: true,
        filter: true,
        cellEditor: CustomArrayTextEditor,
        cellEditorPopup: true,
        wrapText: rowWrapEnabled,
        autoHeight: rowWrapEnabled,
      })),
      ...evaluatorColumns.map((colDef) => ({
        ...colDef,
        headerTooltip: colDef.headerTooltip || colDef.field,
      }))
    ];

    updateColumnDefs(newColumnDefs);
    setGridData(sampleGridData);
  };

  const getSampleData = () => {
    const gridData: RowData[] = [
      // Entries for "Write an email promoting our new electric SUV to potential customers."
      {
        id: 1,
        Input: "Write an email promoting our new electric SUV to potential customers.",
        Response:
          "Dear Valued Customer,\n\nWe are excited to introduce our brand-new electric SUV, the EcoDrive X. With zero emissions and a range of 300 miles, it redefines efficient driving.\n\nBest regards,\nAutoCorp Sales Team",
        Context: "",
        Reference: "",
        Notes: "",
        "Reward responses that highlight key features": 0.8,
        "Penalize responses that contain grammatical errors": 0.0,
        "Response passes if it includes a call-to-action": 1.0,
        "Response fails if it lacks professional tone": 0.0,
      },
      {
        id: 2,
        Input: "Write an email promoting our new electric SUV to potential customers.",
        Response:
          "Hello,\n\nCheck out our new electric SUV! It's eco-friendly and super cool. Get yours now!\n\nCheers,\nAutoCorp",
        Context: "",
        Reference: "",
        Notes: "",
        "Reward responses that highlight key features": 0.6,
        "Penalize responses that contain grammatical errors": 0.0,
        "Response passes if it includes a call-to-action": 1.0,
        "Response fails if it lacks professional tone": 1.0,
      },
      {
        id: 3,
        Input: "Write an email promoting our new electric SUV to potential customers.",
        Response:
          "Dear Valued Customer,\n\nIntroducing the EcoDrive X—our latest electric SUV. Experience unparalleled performance with zero emissions. Schedule a test drive today!\n\nSincerely,\nAutoCorp Sales Team",
        Context: "",
        Reference: "",
        Notes: "",
        "Reward responses that highlight key features": 1.0,
        "Penalize responses that contain grammatical errors": 0.0,
        "Response passes if it includes a call-to-action": 1.0,
        "Response fails if it lacks professional tone": 0.0,
      },
      // Entries for "Compose a follow-up email to a client interested in our latest sedan."
      {
        id: 4,
        Input: "Compose a follow-up email to a client interested in our latest sedan.",
        Response:
          "Dear Mr. Smith,\n\nThank you for your interest in our 2023 LuxSedan. We would like to invite you for a test drive at your convenience.\n\nBest regards,\nAutoCorp Sales Team",
        Context: "",
        Reference: "",
        Notes: "",
        "Reward responses that personalize the message": 0.9,
        "Penalize responses that contain errors": 0.0,
        "Response passes if it offers next steps": 1.0,
        "Response fails if it lacks contact information": 0.0,
      },
      {
        id: 5,
        Input: "Compose a follow-up email to a client interested in our latest sedan.",
        Response: "Hi,\n\nJust checking if you're still interested in our sedan. Let us know.\n\nThanks,\nAutoCorp",
        Context: "",
        Reference: "",
        Notes: "",
        "Reward responses that personalize the message": 0.5,
        "Penalize responses that contain errors": 0.0,
        "Response passes if it offers next steps": 0.0,
        "Response fails if it lacks contact information": 1.0,
      },
      {
        id: 6,
        Input: "Compose a follow-up email to a client interested in our latest sedan.",
        Response:
          "Dear Ms. Johnson,\n\nWe hope this message finds you well. Following up on your interest in the 2023 LuxSedan, we have exciting financing options available. Please contact us to discuss further.\n\nWarm regards,\nAutoCorp Sales Team\n(555) 123-4567",
        Context: "",
        Reference: "",
        Notes: "",
        "Reward responses that personalize the message": 1.0,
        "Penalize responses that contain errors": 0.0,
        "Response passes if it offers next steps": 1.0,
        "Response fails if it lacks contact information": 0.0,
      },
      // Entries for "Draft an email offering a special discount on last year's car models."
      {
        id: 7,
        Input: "Draft an email offering a special discount on last year's car models.",
        Response:
          "Dear Customer,\n\nFor a limited time, enjoy up to 20% off on our 2022 models. Don't miss this exclusive offer!\n\nBest,\nAutoCorp Sales",
        Context: "",
        Reference: "",
        Notes: "",
        "Reward responses that mention discount details": 0.8,
        "Penalize responses that create urgency unethically": 0.0,
        "Response passes if it includes a call-to-action": 1.0,
        "Response fails if it lacks professional tone": 0.0,
      },
      {
        id: 8,
        Input: "Draft an email offering a special discount on last year's car models.",
        Response: "Hey,\n\nWe have some old cars on sale. Buy now!\n\nAutoCorp",
        Context: "",
        Reference: "",
        Notes: "",
        "Reward responses that mention discount details": 0.4,
        "Penalize responses that create urgency unethically": 0.0,
        "Response passes if it includes a call-to-action": 0.0,
        "Response fails if it lacks professional tone": 1.0,
      },
      {
        id: 9,
        Input: "Draft an email offering a special discount on last year's car models.",
        Response:
          "Dear Valued Customer,\n\nWe are pleased to offer a special 25% discount on our remaining 2022 models. This is a great opportunity to own a premium vehicle at an exceptional price. Contact us today to learn more.\n\nSincerely,\nAutoCorp Sales Team",
        Context: "",
        Reference: "",
        Notes: "",
        "Reward responses that mention discount details": 1.0,
        "Penalize responses that create urgency unethically": 0.0,
        "Response passes if it includes a call-to-action": 1.0,
        "Response fails if it lacks professional tone": 0.0,
      },
      // Entries for "Write an email to confirm an appointment for a test drive."
      {
        id: 10,
        Input: "Write an email to confirm an appointment for a test drive.",
        Response:
          "Dear Mr. Lee,\n\nThis is to confirm your appointment for a test drive of the EcoDrive X on March 5th at 2:00 PM. We look forward to seeing you.\n\nBest regards,\nAutoCorp Sales Team",
        Context: "",
        Reference: "",
        Notes: "",
        "Reward responses that confirm details accurately": 0.9,
        "Penalize responses that contain errors in date/time": 0.0,
        "Response passes if it is courteous and professional": 1.0,
        "Response fails if missing key information": 0.0,
      },
      {
        id: 11,
        Input: "Write an email to confirm an appointment for a test drive.",
        Response: "Hi,\n\nYour test drive is confirmed.\n\nThanks,\nAutoCorp",
        Context: "",
        Reference: "",
        Notes: "",
        "Reward responses that confirm details accurately": 0.5,
        "Penalize responses that contain errors in date/time": 0.0,
        "Response passes if it is courteous and professional": 0.0,
        "Response fails if missing key information": 1.0,
      },
      {
        id: 12,
        Input: "Write an email to confirm an appointment for a test drive.",
        Response:
          "Dear Ms. Brown,\n\nWe are writing to confirm your test drive appointment for the LuxSedan on April 12th at 10:00 AM. If you have any questions or need to reschedule, please do not hesitate to contact us.\n\nWarm regards,\nAutoCorp Sales Team\n(555) 123-4567",
        Context: "",
        Reference: "",
        Notes: "",
        "Reward responses that confirm details accurately": 1.0,
        "Penalize responses that contain errors in date/time": 0.0,
        "Response passes if it is courteous and professional": 1.0,
        "Response fails if missing key information": 0.0,
      },
    ];

    const columnDefsSample: ColDef[] = [
      { field: "Input", sortable: true, filter: true },
      { field: "Response", sortable: true, filter: true },
      { field: "Context", sortable: true, filter: true },
      { field: "Reference", sortable: true, filter: true },
      { field: "Notes", sortable: true, filter: true },
      {
        field: "Reward responses that highlight key features",
        sortable: true,
        filter: true,
        cellEditor: CustomArrayTextEditor,
        cellEditorPopup: true,
      },
      {
        field: "Penalize responses that contain grammatical errors",
        sortable: true,
        filter: true,
        cellEditor: CustomArrayTextEditor,
        cellEditorPopup: true,
      },
      {
        field: "Response passes if it includes a call-to-action",
        sortable: true,
        filter: true,
        cellEditor: CustomArrayTextEditor,
        cellEditorPopup: true,
      },
      {
        field: "Response fails if it lacks professional tone",
        sortable: true,
        filter: true,
        cellEditor: CustomArrayTextEditor,
        cellEditorPopup: true,
      },
      {
        field: "Reward responses that personalize the message",
        sortable: true,
        filter: true,
        cellEditor: CustomArrayTextEditor,
        cellEditorPopup: true,
      },
      {
        field: "Penalize responses that contain errors",
        sortable: true,
        filter: true,
        cellEditor: CustomArrayTextEditor,
        cellEditorPopup: true,
      },
      {
        field: "Response passes if it offers next steps",
        sortable: true,
        filter: true,
        cellEditor: CustomArrayTextEditor,
        cellEditorPopup: true,
        // cellStyle is handled in DataGrid's defaultColDef
      },
      {
        field: "Response fails if missing contact information",
        sortable: true,
        filter: true,
        cellEditor: CustomArrayTextEditor,
        cellEditorPopup: true,
        // cellStyle is handled in DataGrid's defaultColDef
      },
      {
        field: "Reward responses that confirm details accurately",
        sortable: true,
        filter: true,
        cellEditor: CustomArrayTextEditor,
        cellEditorPopup: true,
      },
      {
        field: "Penalize responses that contain errors in date/time",
        sortable: true,
        filter: true,
        cellEditor: CustomArrayTextEditor,
        cellEditorPopup: true,
      },
      {
        field: "Response passes if it is courteous and professional",
        sortable: true,
        filter: true,
        cellEditor: CustomArrayTextEditor,
        cellEditorPopup: true,
      },
      {
        field: "Response fails if missing key information",
        sortable: true,
        filter: true,
        cellEditor: CustomArrayTextEditor,
        cellEditorPopup: true,
      }
    ];

    return { gridData: gridData, columnDefs: columnDefsSample };
  };

  const toggleRowWrap = () => {
    setRowWrapEnabled(!rowWrapEnabled);
  };

  const onDropCriteria = (criteriaToRemove: string[]) => {
    console.log("Dropping criteria:", criteriaToRemove);

    updateColumnDefs((prevDefs) => {
      const updatedDefs = prevDefs.filter(col => !criteriaToRemove.includes(col.field!));
      console.log("Updated columnDefs:", updatedDefs);
      return updatedDefs;
    });

    setGridData((prevData) => {
      const updatedData = prevData.map(row => {
        const updatedRow = { ...row };
        criteriaToRemove.forEach(criteria => delete updatedRow[criteria]);
        return updatedRow;
      });
      console.log("Updated gridData:", updatedData);
      return updatedData;
    });

    toast.success("Dropped missing evaluator criteria successfully.");
  };


  // Compute evaluator columns
  const evaluatorColumns = useMemo(() => {
    return columnDefs
      .map((col) => col.field!)
      .filter((field) => !DEFAULT_COLUMNS_LOWER.includes(field.toLowerCase()) && field !== "id");
  }, [columnDefs]);

  // Update columnDefs when rowWrapEnabled changes
  useEffect(() => {
    updateColumnDefs((prevDefs) =>
      prevDefs.map((col) => {
        return {
          ...col,
          wrapText: rowWrapEnabled,
          autoHeight: rowWrapEnabled,
        };
      }),
    );
  }, [rowWrapEnabled]);
  useEffect(() => {
    (async () => {
      try {
        const evaluators: EvaluatorResponse[] = await getEvaluatorByUser();
        setEvaluators(evaluators);
        console.log(evaluators)
      } catch (error) {
        console.error("Failed to load evaluator names", error);
      } finally {
        setIsEvaluatorLoaded(true);
      }
    })();
  }, []);
  const handleCellFocused = (event: any) => {
    if (!event.column) return;
    const column = event.column.getId();
    setSelectedColumn(column);
  };

  return (
    <div className="h-full bg-base-100 flex flex-col">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="container mx-auto px-6 flex-1 flex flex-col mb-8 overflow-hidden">
          <div className="flex gap-2 mt-8 mb-6">
          </div>

          <div className="flex gap-6 flex-1 overflow-hidden">
            {activeTab === "data" && (
              <div className="flex-1 flex flex-col overflow-hidden">
                <DataGrid
                  gridData={gridData}
                  columnDefs={columnDefs}
                  onCellValueChanged={handleCellValueChanged}
                  gridRef={gridRef}
                  onGridReady={handleGridReady}
                  rowWrapEnabled={rowWrapEnabled}
                  handleCellFocused={handleCellFocused}
                />
              </div>
            )}
            {activeTab === "data" && (
              <div className="w-72 bg-base-200 rounded-lg p-6 shadow-lg h-full flex flex-col overflow-hidden">
                <DataToolbar
                  onDeleteSelectedRows={deleteSelectedRows}
                  onAddRow={addRow}
                  columns={columnDefs}
                  onDeleteColumn={deleteColumn}
                  onClearColumn={onClearColumn}
                  onEvaluateColumn={onEvaluateColumn}
                  onAddColumn={onAddColumn}
                  onExportJSONL={handleExportJSONL}
                  onExportCSV={handleExportCSV}
                  onImportJSONL={handleImportJSONL}
                  onExportXLSX={handleExportXLSX}
                  onImportXLSX={handleImportXLSX}
                  onImportCSV={handleImportCSV}
                  onEvaluateAll={onEvaluateAll}
                  onLoadSampleData={onLoadSampleData}
                  rowWrapEnabled={rowWrapEnabled}
                  onToggleRowWrap={toggleRowWrap}
                  isEvaluating={isEvaluating}
                  onClearAll={onClearAll}
                  evaluators={evaluators}
                  isEvaluatorsLoaded={isEvaluatorLoaded}
                  gridRef={gridRef}
                  onDeleteSelectedColumns={deleteSelectedColumn}
                  hasEvaluatorColumns={hasEvaluatorColumns}
                />
              </div>
            )}
          </div>
        </main>
        <HistorySidebar onRunSelect={updateGridDataFromRun}  refreshTrigger={refreshHistoryStamp} />
      </div>
      {isMissingEvaluatorModalOpen && (
        <MissingCriteriaModal
          missingCriteria={missingEvaluatorCriteria}
          onClose={() => setIsMissingEvaluatorModalOpen(false)}
          onDropCriteria={onDropCriteria}
        />
      )}
      {/* Add to component's JSX return */}
      {isInvalidEvaluatorModalOpen && (
        <div className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg">Notice: Invalid Evaluator Columns Filtered</h3>
            <p className="py-4">
              The following columns do not match valid evaluator name format and have been automatically filtered out (evaluator names must start with "response passes if", "response fails if", "reward responses", or "penalize responses"):
            </p>
            <div className="max-h-60 overflow-y-auto">
              <ol className="list-decimal ml-6 space-y-2">
                {missingEvaluatorCriteria.map((criteria, index) => (
                  <li key={index} className="py-1 border-b">{criteria}</li>
                ))}
              </ol>
            </div>
            <div className="modal-action">
              <button className="btn btn-primary" onClick={() => setIsInvalidEvaluatorModalOpen(false)}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const MissingCriteriaModal: React.FC<{
  missingCriteria: string[],
  onClose: () => void,
  onDropCriteria: (criteriaToRemove: string[]) => void
}> = ({ missingCriteria, onClose, onDropCriteria }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [generatedEvaluators, setGeneratedEvaluators] = useState<{ name: string, criteria: string }[] | null>(null);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const response = await generateEvaluatorByCriteria(missingCriteria);
      setGeneratedEvaluators(response);
    } catch (error) {
      console.error("Error generating evaluators:", error);
    }
    setIsLoading(false);
  };

  return (
    <div className="modal modal-open">
      <div className="modal-box">
        <h3 className="font-bold text-lg">
          {generatedEvaluators ? "Generated Evaluator Names" : "Missing Evaluator Criteria"}
        </h3>

        {!generatedEvaluators ? (
          <>
            <p className="py-4">The following evaluator criteria are missing. Please choose how to proceed:</p>
            <ol className="list-decimal ml-6 max-h-40 overflow-y-auto space-y-2">
              {missingCriteria.map((criteria, index) => (
                <li key={index} className="py-1 border-b">{index + 1}. {criteria}</li>
              ))}
            </ol>

            <div className="modal-action">
              <button className="btn btn-primary" onClick={handleSave} disabled={isLoading}>
                {isLoading ? "Generating..." : "Save Missing Criteria"}
              </button>
              <button className="btn btn-secondary"
                      onClick={() => {
                        onDropCriteria(missingCriteria);
                        onClose();
                      }}
                      disabled={isLoading}
              >
                Drop Missing Criteria
              </button>
            </div>
          </>
        ) : (
          <>
            <p className="py-4">The following evaluator names have been generated:</p>
            <div className="max-h-40 overflow-y-auto border rounded-lg p-3">
              {generatedEvaluators.map((evalItem, index) => (
                <div key={index} className="py-2 border-b">
                  <p className="font-medium">{index + 1}. {evalItem.name}</p>
                  <p className="text-sm text-gray-500">Criteria: {evalItem.criteria}</p>
                </div>
              ))}
            </div>

            <div className="modal-action">
              <button className="btn btn-primary" onClick={onClose}>
                Close
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default APIPlayground;
