import axios, { AxiosRequestConfig } from "axios";
import urlJoin from "url-join";
import { fetchAuthSession } from "aws-amplify/auth";
import { useEffect } from "react";

interface ApiRequestOptions {
  path: string;
  method: string;
  body?: any;
  onSuccess?: (data: any) => void;
  onFailure?: (error: any) => void;
  onFinally?: () => void;
}

const getAccessTokenPromise = async (): Promise<string | null> => {
  try {
    const { tokens } = await fetchAuthSession();
    return tokens?.accessToken?.toString() || null;
  } catch (error) {
    console.error("Error fetching access token:", error);
    return null;
  }
};

export const makeApiRequestPromise = async ({
                                              path,
                                              method,
                                              body,
                                              baseUrl
                                            }: Pick<ApiRequestOptions, "path" | "method" | "body"> & { baseUrl?: string }): Promise<any> => {
  const startTime = performance.now();
  const base = baseUrl || import.meta.env.VITE_EVAL_API_URL;
  const apiUrl = urlJoin(base, path);

  let headers: AxiosRequestConfig["headers"] = {
    "Content-Type": "application/json",
  };

  const token = await getAccessTokenPromise();
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  try {
    const response = await axios({
      method: method,
      url: apiUrl,
      data: JSON.stringify(body),
      headers: headers,
    });

    const endTime = performance.now();
    const timeDiff = endTime - startTime;
    console.log(`Successful ${method} ${path} in ${Math.round(timeDiff)}ms`);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendRowsBatch = async (
  runId: string,
  userId: string,
  rowsBatch: any[],
  baseUrl?: string,
  apiKey?: string
) => {
  const path = "/api/eval/rows";
  const body = {
    run_id: runId,
    user_id: userId,
    rows: rowsBatch.map(row => ({
      ...row
    }))
  };

  try {
    const token = await getAccessTokenPromise();
    const response = await axios({
      method: "POST",
      url: baseUrl ? urlJoin(baseUrl, path) : urlJoin(import.meta.env.VITE_EVAL_API_URL, path),
      data: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "api-key": apiKey || "",
        "Authorization": `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};


export const processAndSendInBatches = async (
  runId: string,
  userId: string,
  allRows: any[],
  baseUrl?: string
) => {
  const MAX_BATCH_SIZE = 2 * 1024 * 1024;
  let currentBatch: any[] = [];
  let currentBatchSize = 0;

  for (const row of allRows) {
    const rowSize = new TextEncoder().encode(
      JSON.stringify({
        input: row.Input,
        response: row.Response
      })
    ).length;

    if (currentBatchSize + rowSize > MAX_BATCH_SIZE && currentBatch.length > 0) {
      await sendRowsBatch(runId, userId, currentBatch, baseUrl);
      currentBatch = [];
      currentBatchSize = 0;
    }

    currentBatch.push(row);
    currentBatchSize += rowSize;
  }

  if (currentBatch.length > 0) {
    await sendRowsBatch(runId, userId, currentBatch, baseUrl);
  }
};
export const fetchUserIdByEmail = async (email: string): Promise<string> => {
  const data = await makeApiRequestPromise({
    path: '/api/user/put_user_by_email',
    method: 'PUT',
    body: { email },
    baseUrl: import.meta.env.VITE_PLATFORM_BACKEND_API_URL
  });
  return data.id;
};


export interface EvaluationPayload {
  messages?: { role: string; content: string }[];
  evaluation_criteria?: string;
  run_id?: string;
  user_id?: string;
  reference_output?: string | null;
  input?: string;
  response?: string;
  context?: string[];
  reference?: string;
}

export interface EvaluationResult {
  score: number | null;
  detail?: string;
  explanation?: string;
}

export async function makeEvaluationRequest(
  path: string,
  payload: EvaluationPayload,
  baseUrl?: string,
  apiKey?: string
): Promise<EvaluationResult> {
  try {
    const token = await getAccessTokenPromise();
    const finalUrl = baseUrl
      ? urlJoin(baseUrl, path)
      : urlJoin(import.meta.env.VITE_EVAL_API_URL, path);

    let headers: AxiosRequestConfig["headers"] = {
      "Content-Type": "application/json",
    };
    if (apiKey) {
      headers["api-key"] = apiKey;
    }
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const response = await axios({
      method: "POST",
      url: finalUrl,
      data: JSON.stringify(payload),
      headers: headers,
    });

    if ("passed" in response.data) {
      return {
        score: response.data.passed ? 1 : 0,
        explanation: response.data.explanation,
      };
    }

    return {
      score: response.data.score ?? null,
      detail: response.data.detail,
      explanation: response.data.explanation,
    };
  } catch (error: any) {
    console.error("Evaluation request failed:", error);

    if (error.response && error.response.data) {
      return {
        score: null,
        detail: error.response.data.detail || `API Error: ${error.response.status}`,
      };
    }

    return {
      score: null,
      detail: error.message || "An unexpected error occurred",
    };
  }
}

export async function makeEvaluateRequest(
  input: string,
  response: string,
  context: string,
  reference: string,
  evaluatorName: string,
  runId: string,
  apiKey?: string
): Promise<EvaluationResult> {
  try {
    const baseUrl = import.meta.env.VITE_PLATFORM_BACKEND_API_URL;
    if (typeof response !== "string" || response.trim().length === 0) {
      return {
        score: null,
        detail: "Response is invalid or empty.",
      };
    }

    const evaluatorNameLower = evaluatorName.toLowerCase();
    if (
      evaluatorNameLower.startsWith("reward responses") ||
      evaluatorNameLower.startsWith("penalize responses")
    ) {
      // Reward / Penalize
      const path = "/api/eval/reward";
      const payload: EvaluationPayload =
        input.trim() === ""
          ? {
            messages: [{ role: "assistant", content: response }],
            evaluation_criteria: evaluatorName,
            run_id: runId,
          }
          : {
            messages: [
              { role: "user", content: input },
              { role: "assistant", content: response },
            ],
            evaluation_criteria: evaluatorName,
            run_id: runId,
          };

      return await makeEvaluationRequest(path, payload, baseUrl, apiKey);

    } else if (
      evaluatorNameLower.startsWith("response passes if") ||
      evaluatorNameLower.startsWith("response fails if")
    ) {
      // Binary
      const path = "/api/eval/binary";
      const payload: EvaluationPayload = {
        messages: [
          { role: "user", content: input },
          { role: "assistant", content: response },
        ],
        reference_output: reference || null,
        evaluation_criteria: evaluatorName,
        run_id: runId,
      };

      return await makeEvaluationRequest(path, payload, baseUrl, apiKey);

    } else if (evaluatorNameLower.startsWith("context faithfulness")) {
      // Faithfulness
      const path = "/api/eval/verify/faithfulness";
      const payload: EvaluationPayload = {
        messages: [
          { role: "user", content: input },
          { role: "assistant", content: response },
        ],
        evaluation_criteria: evaluatorName,
        input,
        response,
        context: [context],
        run_id: runId,
      };

      return await makeEvaluationRequest(path, payload, baseUrl, apiKey);

    } else if (evaluatorNameLower.startsWith("context recall")) {
      // Context Recall
      const path = "/api/eval/verify/context_recall";
      const payload: EvaluationPayload = {
        messages: [
          { role: "user", content: input },
          { role: "assistant", content: response },
        ],
        evaluation_criteria: evaluatorName,
        input,
        response,
        context: [context],
        reference,
        run_id: runId,

      };

      return await makeEvaluationRequest(path, payload, baseUrl, apiKey);

    } else if (evaluatorNameLower.startsWith("context precision")) {
      // Context Precision
      const path = "/api/eval/verify/context_precision";
      const payload: EvaluationPayload = {
        messages: [
          { role: "user", content: input },
          { role: "assistant", content: response },
        ],
        evaluation_criteria: evaluatorName,
        input,
        response,
        context: [context],
        reference: reference,
        run_id: runId,
      };

      return await makeEvaluationRequest(path, payload, baseUrl, apiKey);

    } else {
      return {
        score: null,
        detail: `Unknown evaluator: ${evaluatorName}`,
      };
    }
  } catch (error: any) {
    console.error(`Evaluation error for evaluator "${evaluatorName}":`, error);
    return {
      score: null,
      detail: `Error: ${error.message || "An unexpected error occurred"}`,
    };
  }
}

export interface CreateRunRequest {
  run_id?: string;
  run_name?: string;
}

export const createRun = async (
  runId: string,
  fileName: string|null,
): Promise<any> => {
  const path = "/api/eval/run";
  const url = urlJoin(import.meta.env.VITE_PLATFORM_BACKEND_API_URL, path);
  const token = await getAccessTokenPromise();
  const headers: AxiosRequestConfig["headers"] = {
    "Authorization": `Bearer ${token}`,
  };

  const body: CreateRunRequest = { run_id: runId, run_name: fileName?.trim() || undefined };

  try {
    const response = await axios.post(url, body, { headers });
    return response.data;
  } catch (error) {
    console.error("Error creating run:", error);
    throw error;
  }
};

export const getRuns = async (): Promise<any> => {
  try {
    const token = await getAccessTokenPromise();
    const response = await axios.get(
      `${import.meta.env.VITE_PLATFORM_BACKEND_API_URL}/api/eval/runs`,
      {
        headers: {
          "Authorization": `Bearer ${token}`,}
      }
    );

    return response.data;
  } catch (error) {
    console.error("Get evaluation history failed:", error);
    throw error;
  }
};

export const getRun = async (
  runId: string
): Promise<any> => {
  try {
    const token = await getAccessTokenPromise();
    const response = await axios.get(
      `${import.meta.env.VITE_PLATFORM_BACKEND_API_URL}/api/eval/run`,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
        params: {
          run_id: runId
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error("Get evaluation history failed:", error);
    throw error;
  }
};

export const renameRun = async (
  runId: string,
  newName: string
): Promise<any> => {
  try {
    const token = await getAccessTokenPromise();
    const response = await axios.patch(
      `${import.meta.env.VITE_PLATFORM_BACKEND_API_URL}/api/run/rename`,
      {
        new_name: newName,
        run_id: runId
      },
      {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept": "application/json, text/plain, */*"
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error("Rename run history failed:", error);
    throw error;
  }
};



