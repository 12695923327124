import React, { useEffect, useState } from "react";
import Sidebar from "./PlatformSidebar";
import {
  deleteEvaluator,
  createEvaluator, generateEvaluator, getEvaluatorByUser, updateEvaluator // Assume this API function is implemented
} from "./EvaluatorApiRequest";

interface FolderData {
  id: number;
  folderName: string;
  createdBy: string;
  createdTime: string;
}

interface EvaluatorData {
  id: number;
  name: string;
  configuration: string;
  type: string;
  created: string;
}

const EvaluatorsPage: React.FC = () => {
  const [folderDetails, setFolderDetails] = useState<FolderData | null>(null);
  const [evaluators, setEvaluators] = useState<EvaluatorData[]>([]);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  // State for add evaluator modal
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isCreateForMeModalOpen, SetIsCreateForMeModalOpen] = useState(false);
  const [newEvaluator, setNewEvaluator] = useState({
    name: "",
    type: "",
    configuration: "",
  });
  const [selectedEvaluatorType, setSelectedEvaluatorType] = useState("");
  const [selectedRewardType, setSelectedRewardType] = useState("");
  const [selectedBinaryType, setSelectedBinaryType] = useState("");
  const [editingEvalCriteria, setEditingEvalCriteria] = useState<string>("");
  const [editedName, setEditedName] = useState<string>("");

  // Fetch evaluators on component mount
  const fetchEvaluators = async () => {
    setLoading(true);
    try {
      const fetchedData = await getEvaluatorByUser();
      const formattedEvaluators = fetchedData.map((evaluator: any) => ({
          id: evaluator.id,
          name: evaluator.evaluator_name,
          configuration: evaluator.evaluate_criteria,
          type: evaluator.type,
          created: new Date(evaluator.created_at).toLocaleString(),
        }));
        setEvaluators(formattedEvaluators);
    } catch (error) {
      console.error("Failed to fetch evaluators:", error);
      alert("Error fetching evaluators.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
      fetchEvaluators();
  }, []);

  // Handle evaluator deletion
  const handleDeleteEvaluator = async (id: number) => {
    if (!confirm("Are you sure you want to delete this evaluator?")) return;

    try {
      await deleteEvaluator(id);

      setEvaluators((prevEvaluators) => prevEvaluators.filter(ev => ev.id !== id));
    } catch (error) {
      console.error("Failed to delete evaluator:", error);
      alert("Error deleting evaluator.");
    } finally {
    }
  };


  const handleEditClick = (criteria: string, name: string) => {
    setEditingEvalCriteria(criteria);
    setEditedName(name);
  };



  const handleUpdateEvaluatorName = async () => {
    if (!editedName.trim() || editingEvalCriteria === "") return;

    try {
      await updateEvaluator({
        evaluator_name: editedName,
        evaluate_criteria: editingEvalCriteria,
      });

      setEvaluators((prev) =>
        prev.map((ev) =>
          ev.configuration === editingEvalCriteria ? { ...ev, name: editedName } : ev
        )
      );

      setEditingEvalCriteria("");
    } catch (error) {
      alert("Error updating evaluator name.");
    }
  };


  // Handle evaluator creation
  const handleAddEvaluator = async () => {
    if (!newEvaluator.name.trim()) {
      alert("Evaluator name cannot be empty.");
      return;
    }

    try {
      setLoading(true);
      await createEvaluator({
        evaluator_name: newEvaluator.name,
        type: selectedEvaluatorType,
        evaluate_criteria: newEvaluator.configuration,
      });
      await fetchEvaluators();
      setIsAddModalOpen(false);
      setNewEvaluator({ name: "", type: "", configuration: "" });
    } catch (error) {
      console.error("Failed to create evaluator:", error);
      alert("Error creating evaluator.");
    } finally {
      setLoading(false);
    }
  };

  const handleGenerate = async (prompt: string) => {
    try {
      setLoading(true);

      const result = await generateEvaluator(prompt);

      alert("Evaluators generated successfully!");
      await fetchEvaluators();
      SetIsCreateForMeModalOpen(false);
      setDescription("");
    } catch (error) {
      console.error("Failed to generate evaluator:", error);
      alert("Error generating evaluator.");
    } finally {
      setLoading(false);
    }
  };

  return (
  <div className="h-full bg-base-100 flex">
    <Sidebar />
    <main className="container mx-auto px-6 py-8 flex-1 flex flex-col overflow-auto">
        {/* Header Section */}
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-3xl font-bold text-gray-900">
          Evaluators Library
        </h3>
        <div className="flex gap-2">
          <button
            className="btn btn-primary"
            onClick={() => setIsAddModalOpen(true)}
          >
            Add Evaluator
          </button>
          <button
            className="btn btn-primary"
            onClick={() => SetIsCreateForMeModalOpen(true)}
          >
            Auto Generate
          </button>
        </div>
      </div>

      {/* Folder Details */}
      {folderDetails && (
        <div className="mb-4 p-4 bg-gray-100 rounded shadow">
          <p><strong>Folder ID:</strong> {folderDetails.id}</p>
          <p><strong>Created By:</strong> {folderDetails.createdBy}</p>
          <p><strong>Created Time:</strong> {folderDetails.createdTime}</p>
        </div>
      )}

      {/* Loading State */}
      {loading && <div className="skeleton flex-auto"></div>}

      {/* Evaluators Table */}
        {!loading && evaluators.length > 0 && (
          <div className="overflow-visible w-full mb-6">
            <table className="table table-zebra w-full">
              <thead>
              <tr>
                <th>Name</th>
                <th>Configuration</th>
                <th>Type</th>
                <th>Created Time</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {evaluators.map((evaluator) => (
                <tr key={evaluator.id}>
                  <td>
                    {editingEvalCriteria === evaluator.configuration? (
                      <input
                        type="text"
                        className="input input-bordered w-full"
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                        onBlur={handleUpdateEvaluatorName}
                        onKeyDown={(e) => e.key === "Enter" && handleUpdateEvaluatorName()}
                        autoFocus
                      />
                    ) : (
                      <span onClick={() => handleEditClick(evaluator.configuration, evaluator.name)}
                            className="cursor-pointer hover:text-blue-500">
      {evaluator.name}
    </span>
                    )}
                  </td>

                  <td>{evaluator.configuration}</td>
                  <td>{evaluator.type}</td>
                  <td>{evaluator.created}</td>
                  <td>
                    <button
                      className="btn btn-xs btn-error"
                      onClick={() => handleDeleteEvaluator(evaluator.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        )}
        {/* Empty State */}
        {!loading && evaluators.length === 0 && (
          <p className="text-gray-500">No evaluators found.</p>
        )}

        {/* Add Evaluator Modal */}
        {isAddModalOpen && (
          <div className="modal modal-open">
            <div className="modal-box">
              <h3 className="font-bold text-lg">Select Evaluator Category</h3>
              <div className="mt-4">
                {/* Evaluator Type Selection */}
                <div>
                  <label className="label">
                    <span className="label-text">Evaluator Type</span>
                  </label>
                  <select
                    value={selectedEvaluatorType}
                    onChange={(e) => {
                      setSelectedEvaluatorType(e.target.value);
                      setNewEvaluator({ ...newEvaluator, type: e.target.value });
                    }}
                    className="select select-bordered w-full"
                  >
                    <option value="" disabled>Select type</option>
                    <option value="Continuous Reward">Continuous Reward</option>
                    <option value="Binary">Binary</option>
                  </select>
                </div>

                {/* Continuous Reward Fields */}
                {selectedEvaluatorType === "Continuous Reward" && (
                  <>
                    <div className="mt-4">
                      <label className="label">
                        <span className="label-text">Evaluator Criteria</span>
                      </label>
                      <div className="flex items-center space-x-2">
                        <select
                          value={selectedRewardType}
                          onChange={(e) => setSelectedRewardType(e.target.value)}
                          className="select select-bordered"
                        >
                          <option value="" disabled>
                            Choose reward type
                          </option>
                          <option value="Reward responses">Reward responses</option>
                          <option value="Penalize responses">Penalize responses</option>
                        </select>
                        <input
                          type="text"
                          placeholder="Enter evaluator criteria"
                          className="input input-bordered flex-1"
                          onChange={(e) => setNewEvaluator({
                            ...newEvaluator,
                            configuration: `${selectedRewardType} ${e.target.value}`
                          })}
                        />
                      </div>
                      <div className="mt-4">
                      <input
                        type="text"
                        placeholder="Enter evaluator name"
                        className="input input-bordered w-full"
                        onChange={(e) => setNewEvaluator({
                          ...newEvaluator,
                          name: e.target.value
                        })}
                      />
                    </div>
                    </div>
                  </>
                )}

                {/* Binary Type Fields */}
                {selectedEvaluatorType === "Binary" && (
                  <div className="space-y-2">
                    <div>
                      <label className="label">
                        <span className="label-text">Condition Type</span>
                      </label>
                      <select
                        value={selectedBinaryType}
                        onChange={(e) => setSelectedBinaryType(e.target.value)}
                        className="select select-bordered w-full"
                      >
                        <option value="" disabled>Select condition</option>
                        <option value="Response passes if">Response passes if</option>
                        <option value="Response fails if">Response fails if</option>
                      </select>
                    </div>
                    <div>
                      <label className="label">
                        <span className="label-text">Criteria</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter evaluation criteria"
                        className="input input-bordered w-full"
                        value={newEvaluator.name}
                        onChange={(e) => setNewEvaluator({
                          ...newEvaluator,
                          configuration: `${selectedBinaryType} ${e.target.value}`,
                          name: e.target.value
                        })}
                      />
                    </div>
                  </div>
                )}
              </div>

              {/* Modal Actions */}
              <div className="modal-action">
                <button
                  className="btn btn-primary"
                  onClick={handleAddEvaluator}
                  disabled={loading || !newEvaluator.name.trim()}
                >
                  {loading ? "Creating..." : "Create"}
                </button>
                <button
                  className="btn"
                  onClick={() => setIsAddModalOpen(false)}
                  disabled={loading}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      {isCreateForMeModalOpen && (
        <div className="modal modal-open" onClick={() => SetIsCreateForMeModalOpen(false)}>
          <div className="modal-box flex flex-col" onClick={(e) => e.stopPropagation()}>
            <p className="text-sm mb-2">
              Describe your app or paste a system message and we'll create your evaluation criteria for you.
            </p>

            {/* State to capture textarea value */}
            <textarea
              className="textarea textarea-bordered w-full h-48 mb-4"
              placeholder="Enter description here..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>

            <div className="flex justify-end">
              <button
                className={`btn btn-primary flex items-center ${loading ? "btn-disabled" : ""}`}
                onClick={async () => {
                  if (!description.trim()) {
                    alert("Description cannot be empty.");
                    return;
                  }
                  await handleGenerate(description);
                }}
                disabled={loading}
              >
                {loading ? "Generating..." : "Create"}
                {!loading && <span className="wand-icon ml-2"></span>}
              </button>
            </div>
          </div>
        </div>
      )}

      </main>
    </div>
  );
};

export default EvaluatorsPage;