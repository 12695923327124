import React, { createContext, useContext, useState, useCallback } from "react";
import { ColDef } from "ag-grid-community";
import { v4 as uuidv4 } from 'uuid';
import { CustomArrayTextEditor } from "./APIPlayground";

export interface RowData {
  id: number;
  [key: string]: any;
}

export const DEFAULT_COLUMNS = ["Input", "Response", "Context", "Reference", "Notes"];

export type RowDataColDef = ColDef<RowData> & {
  field?: string;
  headerTooltip?: string;
  minWidth?: number;
  width?: number;
};

interface DataGridState {
  gridData: RowData[];
  columnDefs: RowDataColDef[];
  rowWrapEnabled: boolean;
  fileName: string | null;
  runId: string;
  criteriaToNameMap: Map<string, string>;
}

interface DataGridContextType extends DataGridState {
  setGridData: (value: RowData[] | ((prevState: RowData[]) => RowData[])) => void;
  setColumnDefs: (defs: RowDataColDef[] | ((prev: RowDataColDef[]) => RowDataColDef[])) => void;
  setRowWrapEnabled: (enabled: boolean) => void;
  setFileName: (name: string | null) => void;
  setRunId: (id: string) => void;
  setCriteriaToNameMap: (map: Map<string, string>) => void;
  clearAll: () => void;
  updateColumnDefs: (newDefs: ColDef[] | ((prevDefs: ColDef[]) => ColDef[])) => void;
}

const getInitialColumnDefs = (rowWrapEnabled: boolean): RowDataColDef[] => {
  const baseWidth = 150;
  const specialWidth = baseWidth * 3;

  return DEFAULT_COLUMNS.map((col) => ({
    field: col,
    headerTooltip: col,
    sortable: true,
    filter: true,
    editable: true,
    cellEditorPopup: true,
    wrapText: rowWrapEnabled,
    autoHeight: rowWrapEnabled,
    cellStyle: { whiteSpace: rowWrapEnabled ? 'pre-wrap' : 'nowrap' },
    minWidth: col === 'Input' || col === 'Response' ? specialWidth : baseWidth,
    width: col === 'Input' || col === 'Response' ? specialWidth : baseWidth,
  }));
};

const DataGridContext = createContext<DataGridContextType | undefined>(undefined);

export const DataGridProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState<DataGridState>(() => ({
    gridData: [],
    columnDefs: getInitialColumnDefs(false),
    rowWrapEnabled: false,
    fileName: null,
    runId: uuidv4(),
    criteriaToNameMap: new Map(),
  }));

  const setCriteriaToNameMap = useCallback((map: Map<string, string>) => {
    setState(prev => {
      const currentEntries = Array.from(prev.criteriaToNameMap.entries());
      const newEntries = Array.from(map.entries());
      if (JSON.stringify(currentEntries) === JSON.stringify(newEntries)) {
        return prev;
      }
      return { ...prev, criteriaToNameMap: new Map(map) };
    });
  }, []);

  const updateColumnDefs = useCallback((newDefs: ColDef[] | ((prevDefs: ColDef[]) => ColDef[])) => {
    setState(prev => {
      const { criteriaToNameMap } = prev;
      const finalDefs = typeof newDefs === "function" ? newDefs(prev.columnDefs) : newDefs;
      return {
        ...prev,
        columnDefs: finalDefs.map(col => ({
          ...col,
          headerName: criteriaToNameMap.get(col.field!) || col.field,
          headerTooltip: col.field,
          minWidth: col.field === 'Input' || col.field === 'Response' ? 450 : 150,
          width: col.field === 'Input' || col.field === 'Response' ? 450 : (col.width || undefined)
        }))
      };
    });
  }, []);

  const value: DataGridContextType = {
    ...state,
    setCriteriaToNameMap,
    setGridData: (data) => {
      setState(prev => ({
        ...prev,
        gridData: typeof data === "function" ? data(prev.gridData) : data,
      }));
    },

    setColumnDefs: (defs) => {
      setState(prev => ({
        ...prev,
        columnDefs: typeof defs === "function" ? defs(prev.columnDefs) : defs
      }));
    },

    setRowWrapEnabled: (enabled) => {
      setState(prev => ({
        ...prev,
        rowWrapEnabled: enabled,
        columnDefs: prev.columnDefs.map(col => ({
          ...col,
          wrapText: enabled,
          autoHeight: enabled,
          // cellStyle: {
          //   ...col.cellStyle,
          //   whiteSpace: enabled ? 'pre-wrap' : 'nowrap'
          // }
        }))
      }));
    },

    setFileName: (name) => {
      setState(prev => ({ ...prev, fileName: name }));
    },

    setRunId: (id) => {
      setState(prev => ({ ...prev, runId: id }));
    },

    // setCriteriaToNameMap: (map) => {
    //   setState(prev => ({ ...prev, criteriaToNameMap: new Map(map) }));
    // },

    clearAll: () => {
      setState(prev => ({
        gridData: [],
        columnDefs: getInitialColumnDefs(prev.rowWrapEnabled),
        rowWrapEnabled: prev.rowWrapEnabled,
        fileName: null,
        runId: uuidv4(),
        criteriaToNameMap: new Map(),
      }));
    },

    updateColumnDefs,
  };

  return (
    <DataGridContext.Provider value={value}>
      {children}
    </DataGridContext.Provider>
  );
};

export const useDataGrid = () => {
  const context = useContext(DataGridContext);
  if (!context) {
    throw new Error('useDataGrid must be used within a DataGridProvider');
  }
  return context;
};
