import React, { useState, useEffect } from "react";
import { signOut, updatePassword } from "aws-amplify/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Sidebar from "./PlatformSidebar";
import { makeApiRequestPromise } from "./ApiRequest";

type TabType = "api" | "security";

export const listApiKey = async (): Promise<string[]> => {
  try {
    const response = await makeApiRequestPromise({
      path: "/api/keys/list",
      method: "GET",
    });

    const apiKeys = response.map((item: { api_key: string }) => item.api_key);
    return apiKeys;
  } catch (error) {
    console.error("Error fetching API keys list:", error);
    toast.error("Failed to retrieve API keys");
    return [];
  }
};

export default function AccountSettings() {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [activeTab, setActiveTab] = useState<TabType>("api");
  const [apiKeys, setApiKeys] = useState<string[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    listApiKey().then((keys) => {
      setApiKeys(keys);
    });
  }, []);

  const handlePasswordChange = async () => {
    if (!newPassword.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)) {
      toast.error("Password must be at least 8 characters long, including a number and a symbol.");
      return;
    }

    try {
      await updatePassword({ oldPassword: currentPassword, newPassword });
      toast.success("Password changed successfully");
      setCurrentPassword("");
      setNewPassword("");
    } catch (error: any) {
      console.error("Password change error:", error);

      if (error.message.includes("LimitExceededException")) {
        toast.error("Too many password change requests, please try again after some time.");
      } else {
        toast.error(`Password change error: ${error.message || "Unknown error"}`);
      }
    }
  };

  const generateApiKey = async () => {
    if (isGenerating) return;

    setIsGenerating(true);
    try {
      const newApiKey = await makeApiRequestPromise({
        path: "/api/keys/create",
        method: "POST",
        baseUrl: import.meta.env.VITE_EVAL_API_URL as string,
      });

      await navigator.clipboard.writeText(newApiKey);
      toast.success("New API key has been generated and copied to clipboard");
      const keys = await listApiKey();
      setApiKeys(keys);
    } catch (error) {
      toast.error("Failed to generate API key");
      console.error("API key generation error:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const deleteApiKey = async (keyToDelete: string) => {
    try {
      await makeApiRequestPromise({
        path: "/api/keys/delete",
        method: "DELETE",
        baseUrl: import.meta.env.VITE_EVAL_API_URL as string,
        body: { api_key: keyToDelete },
      });
      toast.success("API key successfully deleted");
      const keys = await listApiKey();
      setApiKeys(keys);
    } catch (error) {
      toast.error("Failed to delete API key");
      console.error("API key deletion error:", error);
    }
  };

  const copyApiKey = async (key: string) => {
    try {
      await navigator.clipboard.writeText(key);
      toast.success("API key copied to clipboard");
    } catch (error) {
      toast.error("Failed to copy API key");
    }
  };

  const maskApiKey = (key: string) => {
    if (key.length <= 8) return key;
    return `${key.slice(0, 4)}...${key.slice(-4)}`;
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate("/");
      window.location.reload(); // Add this line to force a page refresh
    } catch (error) {
      console.error("Error signing out:", error);
      toast.error("Error signing out");
    }
  };

  const renderApiTab = () => (
    <div className="card bg-base-100 shadow-xl mb-6">
      <div className="card-body">
        <div className="flex justify-between items-center mb-4">
          <h2 className="card-title">API Keys</h2>
          <button onClick={generateApiKey} disabled={isGenerating} className="btn btn-primary">
            {isGenerating ? "Generating..." : "Generate New API Key"}
          </button>
        </div>

        <div className="space-y-2">
          {apiKeys.length === 0 && (
            <div className="alert alert-info">
              <span>No API keys have been generated yet.</span>
            </div>
          )}

          {apiKeys.map((key, index) => (
            <div key={index} className="card bg-base-200">
              <div className="card-body p-4 flex-row justify-between items-center">
                <code className="text-sm">{maskApiKey(key)}</code>
                <div className="flex gap-2">
                  <button onClick={() => copyApiKey(key)} className="btn btn-ghost btn-sm">
                    Copy
                  </button>
                  <button
                    onClick={() => {
                      if (
                        window.confirm("Are you sure you want to delete this API key? This action cannot be undone.")
                      ) {
                        deleteApiKey(key);
                      }
                    }}
                    className="btn btn-ghost btn-sm text-error"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const renderSecurityTab = () => (
    <>
      <div className="card bg-base-100 shadow-xl mb-6">
        <div className="card-body">
          <h2 className="card-title">Change Password</h2>
          <div className="form-control gap-4">
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="input input-bordered"
              placeholder="Current Password"
            />
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="input input-bordered"
              placeholder="New Password"
            />
          </div>
          <div className="card-actions justify-end mt-4">
            <button onClick={handlePasswordChange} className="btn btn-primary">
              Update Password
            </button>
          </div>
        </div>
      </div>

      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title text-error">Danger Zone</h2>
          <button onClick={handleSignOut} className="btn btn-outline btn-error">
            Sign Out
          </button>
          <p className="text-sm mt-4">
            If you wish to delete your account, please send an email to support@composo.ai.
          </p>
        </div>
      </div>
    </>
  );

  return (
    <div className="w-full h-full overflow-y-auto flex">
      <Sidebar />

      {/* Control Center Container */}
      <div className="flex-1 p-6 mt-12">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-2xl font-bold mb-8">Control Center</h1>

          <div className="tabs tabs-boxed mb-8">
            <button
              className={`tab ${activeTab === "api" ? "tab-active" : ""}`}
              onClick={() => setActiveTab("api")}
            >
              API Keys
            </button>
            <button
              className={`tab ${activeTab === "security" ? "tab-active" : ""}`}
              onClick={() => setActiveTab("security")}
            >
              Security
            </button>
          </div>

          {activeTab === "api" && renderApiTab()}
          {activeTab === "security" && renderSecurityTab()}
        </div>
      </div>
    </div>
  );
}
