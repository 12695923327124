import React, { Fragment, useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import { getRuns } from "./ApiRequest";

interface HistorySidebarProps {
  onRunSelect: (runId: string) => void;
  selectedRuns: string[];
  isSidebarOpen: boolean;
  handleToggle: () => void;
}

const HistorySidebar: React.FC<HistorySidebarProps> = ({ onRunSelect, selectedRuns, isSidebarOpen, handleToggle }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [runsData, setRunsData] = useState<any[]>([]);


  useEffect(() => {
    const fetchRuns = async () => {
      try {
        const runsResponse = await getRuns();

        const groupedRuns = runsResponse.runs.reduce((acc: any, run: any) => {
          const date = new Date(run.created_at * 1000).toLocaleDateString("en-US", {
            weekday: "long",
            year: "numeric",
            month: "short",
            day: "numeric",
          });

          if (!acc[date]) {
            acc[date] = [];
          }

          acc[date].push({
            runId: run.runid,
            time: new Date(run.created_at * 1000).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }),
            timestamp: run.created_at,
            content: run.name,
          });

          return acc;
        }, {});

        const sortedRuns = Object.entries(groupedRuns)
          .sort(([dateA], [dateB]) => new Date(dateB).getTime() - new Date(dateA).getTime())
          .map(([date, items]) => ({
            date,
            items: (items as any[]).sort((a, b) => b.timestamp - a.timestamp),
          }));
        setRunsData(sortedRuns);
      } catch (error) {
        console.error("Error fetching runs:", error);
      }
    };

    fetchRuns();
  }, []);

  const handleRunClick = (runId: string) => {
    onRunSelect(runId);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleSidebar = () => {
    handleToggle();
  };

  return (
    <div className="flex h-full relative">
      {/* Sidebar */}
      <div
        className={`fixed top-0 right-0 h-full bg-base-100 text-base-content border-l shadow-inner transform transition-transform duration-300 ${
          isSidebarOpen ? "translate-x-0" : "translate-x-full"
        } w-96 flex flex-col overflow-y-auto pt-8`}
      >
        <div className="p-4">
          <h2 className="text-xl font-bold mb-4">Select runs to view</h2>
          {runsData.length > 0 ? (
            runsData.map((entry, idx) => (
              <div key={idx} className="mb-6">
                <h3 className="text-sm font-semibold text-gray-500 mb-4">{entry.date}</h3>
                <hr className="border-t border-gray-300" />
                <div className="space-y-4 mt-4">
                  {entry.items.map((item: any, itemIdx: number) => (
                    <div
                      key={itemIdx}
                      className={`p-4 rounded-lg shadow-sm border cursor-pointer transition-colors ${
                        selectedRuns.includes(item.runId)
                          ? "bg-primary text-white border-gray-700" 
                          : "bg-gray-100 border-gray-200 hover:bg-gray-200"
                      }`}
                      onClick={() => handleRunClick(item.runId)}
                    >
                      <div className={`text-sm ${selectedRuns.includes(item.runId) ? "text-white" : "text-gray-500"} mb-2`}>
                        {item.time}
                      </div>
                      <p className="text-sm leading-relaxed">{item.content}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500">No history available.</p>
          )}
        </div>
      </div>

      {/* Toggle Button */}
      <button
        onClick={toggleSidebar}
        className={`fixed top-1/2 transform -translate-y-1/2 z-10 bg-primary text-white p-2 rounded-l-full shadow-lg hover:bg-primary-focus transition-transform duration-300 ${
          isSidebarOpen ? "right-96" : "right-0"
        }`}
        style={{ transition: "right 0.3s ease" }}
      >
        {isSidebarOpen ? <ChevronRightIcon className="w-6 h-6" /> : <ChevronLeftIcon className="w-6 h-6" />}
      </button>

      {/* Modal */}
      <Transition show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10" onClose={closeModal}>
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full">
                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-titlebrown p-6">
                  Talk to us!
                </Dialog.Title>
                <button onClick={closeModal} className="absolute top-6 right-6">
                  <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                </button>
                <Dialog.Description as="p" className="text-sm text-maindarkgray px-6">
                  We're here to help! You can either send us an email or book a call.
                </Dialog.Description>
                <div className="p-6 flex flex-col items-center">
                  <a
                    href="mailto:seb@composo.ai"
                    className="link-reset btn btn-primary mb-4 px-14"
                    onClick={closeModal}
                  >
                    Email Us
                  </a>
                  <a
                    href="https://calendly.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-reset btn btn-outline px-10"
                    onClick={closeModal}
                  >
                    Book a call
                  </a>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default HistorySidebar;
